import { styled } from '@mui/material/styles'
import { ENV } from '../ENV'

export const TextRegular = styled('span')({
  fontWeight: 400,
  fontSize: '14px'
  // color: ENV.colors.primary_text
})

export const TextMedium = styled('span')({
  fontWeight: 500,
  fontSize: '14px'
})

export const TextSemiBold = styled('span')({
  fontWeight: 600,
  fontSize: '14px'
})

export const TextBold = styled('span')({
  fontWeight: 700,
  fontSize: '14px'
})

export const TextHover = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  transition: 'color 0.2s ease',
  '&:hover': {
    color: theme.palette.primary.main
  }
}))
