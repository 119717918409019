import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import {} from './loginReducers'

const appReducer = combineReducers({
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_APP_STATE') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
