import { useState } from 'react'
import {
  Grid,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  useTheme
} from '@mui/material'
import AppButton from '../commonComponents/AppButton'
import {
  checkPrivilege,
  isEmpty,
  showToast,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone
} from '../utils/AppHelper'
import serverData from '../api/ServerData'
import { logoutUser } from '../actions/loginActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserDetails } from '../utils/LocalStorage'
import { Privileges } from '../utils/AppConstants'
import { TextBold } from '../commonComponents/TextStyling'
import PasswordPopperComponent from '../commonComponents/PasswordPopperComponent'
import PhoneInput from '../commonComponents/PhoneInput'

export default function CreateNewUser () {
  const user = getUserDetails()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [adminFirstName, setAdminFirstName] = useState('')
  const [adminLastName, setAdminLastName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const [adminPhone, setAdminPhone] = useState('')
  const [adminType, setAdminType] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState({})

  const [loading, setLoading] = useState(false)

  function validateForm () {
    const error = {}

    if (isEmpty(adminFirstName) && !validateName(adminFirstName)) {
      error.adminFirstName = 'Admin First Name cannot be empty'
    }
    if (isEmpty(adminLastName) && !validateName(adminLastName)) {
      error.adminLastName = 'Admin Last Name cannot be empty'
    }

    if (isEmpty(adminPhone)) {
      error.adminPhone = 'Plese enter phone number'
    } else if (!validatePhone(adminPhone)) {
      error.adminPhone = 'Invalid Phone Number'
    }

    if (isEmpty(adminEmail)) {
      error.adminEmail = 'Email cannot be empty'
    } else if (!validateEmail(adminEmail)) {
      error.adminEmail = 'Invalid Email'
    }
    if (isEmpty(adminType)) {
      error.adminType = 'Please select admin type'
    }

    if (isEmpty(password)) {
      error.password = 'Password cannot be empty'
    } else if (password.length < 8) {
      error.password = 'Password should be 8 characters long'
    } else if (!validatePassword(password)) {
      error.password =
        'Password should contain atleast a letter, number and special character'
    }
    setFormError(error)
    return Object.keys(error).length === 0
  }

  function handleSubmit (event) {
    event.preventDefault()
    if (validateForm()) {
      setLoading(true)
      var body = {
        adminFirstName,
        adminLastName,
        adminEmail,
        adminPhone,
        adminType,
        password
      }

      serverData.createNewUser(
        body,
        result => {
          if (result.status) {
            navigate('/adminuserlist')
            showToast(result.message, false)
          } else {
            showToast(result.message)
          }
          setLoading(false)
        },
        error => {
          setLoading(false)
          if (error.status === 401) {
            dispatch(logoutUser(navigate))
          }
        }
      )
    }
  }

  return (
    <Grid container spacing={0} style={{ flexDirection: 'column' }}>
      <Grid
        item
        style={{
          backgroundColor: theme.palette.background.paper,
          padding: '24px',
          borderRadius: '6px'
        }}
      >
        <Box component='form' onSubmit='handleSubmit'>
          <Grid container direction='row' alignItems='start' spacing={3}>
            <Grid item xs={12}>
              <TextBold style={{ fontSize: '18px' }}>Add Admin</TextBold>
            </Grid>
            <Grid xs={4} item>
              <TextField
                required
                fullWidth
                name='adminFirstName'
                label='Admin First Name'
                type='text'
                id='adminFirstName'
                value={adminFirstName}
                onChange={e => {
                  var { value } = e.target
                  if (value.length < 31) {
                    setAdminFirstName(
                      e.target.value.replace(' ', '').replace(/[^a-zA-z]/g, '')
                    )
                  }
                }}
                error={formError.adminFirstName}
                helperText={formError.adminFirstName}
              />
            </Grid>

            <Grid xs={4} item>
              <TextField
                required
                fullWidth
                name='adminLastName'
                label='Admin Last Name'
                type='text'
                id='adminLastName'
                value={adminLastName}
                onChange={e => {
                  var { value } = e.target
                  if (value.length < 31) {
                    setAdminLastName(
                      e.target.value.replace(' ', '').replace(/[^a-zA-z]/g, '')
                    )
                  }
                }}
                error={formError.adminLastName}
                helperText={formError.adminLastName}
              />
            </Grid>
            <Grid xs={4} item>
              <TextField
                required
                fullWidth
                name='adminEmail'
                label=' Admin Email'
                type='text'
                id='adminEmail'
                value={adminEmail}
                onChange={e => {
                  var { value } = e.target
                  if (value.length < 60) {
                    setAdminEmail(e.target.value)
                  }
                }}
                error={formError.adminEmail}
                helperText={formError.adminEmail}
              />
            </Grid>
            <Grid xs={4} item>
              <PhoneInput
                id='Phone Number'
                label='Phone Number'
                variant='outlined'
                fullWidth
                name='adminPhone'
                value={adminPhone}
                onChange={e => {
                  const input = e.target.value.replace(/\D/g, '')
                  setAdminPhone(input)
                }}
                error={formError.adminPhone}
                helperText={formError.adminPhone}
              />
            </Grid>
            <Grid xs={4} item>
              <FormControl fullWidth error={formError.adminType}>
                <InputLabel id='adminType'>Admin Type</InputLabel>
                <Select
                  id='adminType'
                  label='Admin type'
                  value={adminType}
                  fullWidth
                  onChange={event => setAdminType(event.target.value)}
                  required
                >
                  {checkPrivilege(Privileges.level1, user) && (
                    <MenuItem value='superadmin'>Super Admin</MenuItem>
                  )}
                  <MenuItem value='admin'>Admin</MenuItem>
                  <MenuItem value='manager'>Manager</MenuItem>
                  <MenuItem value='employee'>Employee</MenuItem>
                </Select>
                <FormHelperText>{formError.adminType}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <PasswordPopperComponent
                password={password}
                setPassword={setPassword}
                formError={formError.password}
                isPopper={true}
                title={'Password'}
              />
            </Grid>
            <Grid xs={12} item>
              <AppButton
                loading={loading}
                name={'Create'}
                fullWidth={false}
                onClick={handleSubmit}
                style={{
                  marginRight: '16px',
                  padding: '12px 24px',
                  borderRadius: '8px'
                }}
              />
              <AppButton
                name={'Cancel'}
                fullWidth={false}
                onClick={() => navigate('/adminuserlist')}
                style={{
                  marginRight: '16px',
                  padding: '12px 24px',
                  borderRadius: '8px'
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
