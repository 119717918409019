import { CircularProgress, Grid, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import serverData from '../../api/ServerData'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import SearchComponent from '../../commonComponents/SearchComponent'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.shade,
    color: theme.palette.common.secondary,
    fontSize: 16,
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    padding: '8px 15px'
  }
}))
export default function Candidates ({ status }) {
  const location = useLocation()
  const { jobId, recruiterId } = location.state || {}

  const [candidates, setCandidates] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)

  var dispatch = useDispatch()
  const handleRowClick = row => {
    navigate('/candidatedetails', { state: row })
  }
  useEffect(
    function () {
      getCandidates()
    },
    [status]
  )

  function getCandidates (searchText) {
    setLoading(true)
    serverData.getApplications(
      { status, jobId, recruiterId, searchText },
      result => {
        if (result.status) {
          setCandidates(result.response)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }
  const paginatedData = candidates.slice(page * limit, page * limit + limit)

  return (
    <Grid container xs={12}>
      <Grid container direction='column' sx={{ justifyContent: 'end' }}>
        <Grid item style={{ marginBottom: '16px' }}>
          <SearchComponent
            setOnSearch={searchText => {
              getCandidates(searchText)
            }}
          />
        </Grid>
        {loading ? (
          <Grid
            container
            spacing={2}
            direction={'column'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70vh',
              width: '100%'
            }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : candidates?.length > 0 ? (
          <>
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell> Email</StyledTableCell>
                      <StyledTableCell>Job Title</StyledTableCell>
                      <StyledTableCell>Company Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map(row => (
                      <TableRow
                        key={row.id}
                        onClick={() => handleRowClick(row)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <StyledTableCell>
                          {row.candidateFirstName} {row.candidateLastName}
                        </StyledTableCell>
                        <StyledTableCell>{row.candidateEmail}</StyledTableCell>
                        <StyledTableCell>{row.jobTitle}</StyledTableCell>
                        <StyledTableCell>{row.companyname}</StyledTableCell>
                        <StyledTableCell>
                          <div style={{}}>{row.status}</div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component='div'
                count={candidates.length}
                rowsPerPage={limit}
                page={page}
                onPageChange={(event, newpage) => {
                  setPage(newpage)
                }}
                onRowsPerPageChange={event => {
                  setLimit(parseInt(event.target.value, 10))
                  setPage(0)
                }}
              />
            </Grid>
          </>
        ) : (
          <>
            <NoRecordFound />
          </>
        )}
      </Grid>
    </Grid>
  )
}
