import {
  ClickAwayListener,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  TextField
} from '@mui/material'
import React, { useState, useRef } from 'react'
import { TextRegular } from './TextStyling'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useTheme } from '@mui/styles'

export default function PasswordPopperComponent ({
  password,
  setPassword,
  formError,
  isPopper,
  title
}) {
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const [openPopper, setOpenPopper] = useState(false)
  const anchorRef = useRef(null)
  const passwordValid = [
    { label: 'At least 8 characters long.', regEx: /.{8,}/ },
    { label: 'At least a number.', regEx: /\d/ },
    {
      label: 'At least a uppercase Letter.',
      regEx: /[A-Z]/
    },
    {
      label: 'At least a special character.',
      regEx: /[!@#$%^&*(),.?":{}|<>]/
    }
  ]

  return (
    <FormControl fullWidth error={formError}>
      <TextField
        label={title}
        required
        fullWidth
        value={password}
        error={formError}
        helperText={formError}
        onChange={e => {
          var { value } = e.target
          if (value.length < 60) {
            setPassword(value)
          }
        }}
        inputRef={anchorRef}
        onFocus={() => {
          if (isPopper) {
            setOpenPopper(true)
          }
        }}
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            e.preventDefault()
          }
        }}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                onClick={() => setShowPassword(!showPassword)}
                edge='end'
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {isPopper && (
        <Popper
          open={openPopper}
          anchorEl={anchorRef.current}
          placement='bottom-start'
        >
          <ClickAwayListener
            onClickAway={event => {
              if (!anchorRef.current.contains(event.target)) {
                setOpenPopper(false)
              }
            }}
          >
            <Paper
              sx={{
                padding: '16px',
                width: anchorRef?.current?.clientWidth
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <FormLabel component='legend' style={{ fontSize: '14px' }}>
                    Password strength
                  </FormLabel>
                </Grid>

                <ul style={{ padding: '8px 16px', margin: '0px' }}>
                  {passwordValid.map(item => {
                    const isValid = item.regEx.test(password)
                    return (
                      <li
                        style={{
                          color: isValid
                            ? theme.palette.text.success
                            : theme.palette.text.secondary
                        }}
                      >
                        <Grid item xs={12}>
                          <TextRegular
                            style={{
                              color: isValid
                                ? theme.palette.text.success
                                : theme.palette.text.secondary
                            }}
                          >
                            {item.label}
                          </TextRegular>
                        </Grid>
                      </li>
                    )
                  })}
                </ul>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </FormControl>
  )
}
