import { toast } from 'react-toastify'
import { ChatOperationCodes } from '../utils/AppConstants'
import { userToken } from '../utils/LocalStorage'
import { SocketUrl } from './api_endpoints'

class WebSocketManager {
  constructor () {
    this.handlers = {
      onopen: [],
      onmessage: [],
      onerror: [],
      onclose: []
    }
  }

  init () {
    this.socket = new WebSocket(`${SocketUrl}?token=${userToken}`)

    this.socket.onopen = event => {
      console.log('Websocket connected')
      this.handlers.onopen.forEach(fn => fn(event))
    }
    this.socket.onerror = event => {
      console.log('Websocket error')

      this.handlers.onerror.forEach(fn => fn(event))
    }
    this.socket.onclose = event => {
      console.log('Websocket close', event.code)
      if (event.code !== 1000 && userToken) {
        setTimeout(() => {
          console.log('Websocket reconnect')
          this.init()
        }, 5000)
      }

      this.handlers.onclose.forEach(fn => fn(event))
    }
    this.socket.onmessage = async event => {
      console.log('Websocket - Message from server ', event.data)
      var { response, opcode } = JSON.parse(event.data)
      if (opcode === ChatOperationCodes.newJobPosted) {
        toast(response)
      }

      this.handlers.onmessage.forEach(fn => fn(event))
    }
  }

  addEventListener (type, callback) {
    if (this.handlers[type]) {
      this.handlers[type].push(callback)
    }
  }

  removeEventListener (type, callback) {
    if (this.handlers[type]) {
      this.handlers[type] = this.handlers[type].filter(fn => fn !== callback)
    }
  }

  send (data) {
    this.socket.send(data)
  }
}

export default new WebSocketManager()
