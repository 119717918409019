import { ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import { theme } from './utils/AppColors'
import { CssBaseline } from '@mui/material'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore
} from 'redux'
import { thunk } from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import reducers from './reducers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './screen/Login'
import Dashboard from './screen/Dashboard'
import JobDetails from './screen/jobActions/JobDetails'
import UnProtectedRoutes from './middlewares/UnProtectedRoutes'
import ProtectedRoutes from './middlewares/ProtectedRoutes'
import Candidates from './screen/candidateActions/Candidates'
import CandidateDetails from './screen/candidateActions/CandidateDetails'
import Jobs from './screen/jobActions/Jobs'
import ManageTransaction from './screen/manageTransaction/ManageTransaction'
import ChangePassword from './screen/ChangePassword'
import CreateNewUser from './screen/CreateNewUser'
import ManageUsers from './screen/manageUsers/ManageUsers'
import FAQ from './screen/faq/Faq'
import CreateFAQ from './screen/faq/CreateFaq'
import CreateNotification from './screen/notification/CreateNotification'
import AdminUserList from './screen/admin/AdminUserList'
import MyJobs from './screen/jobActions/MyJobs'
import { ENV } from './ENV'
import AllNotifications from './screen/notification/AllNotifications'

export const store = createStore(reducers, compose(applyMiddleware(thunk)))

function App () {
  // To update title & favicon
  useEffect(() => {
    document.title = `${ENV.product_name} (Admin)`
    let link =
      document.querySelector('link[rel="shortcut icon"]') ||
      document.querySelector('link[rel="icon"]')

    if (!link) {
      link = document.createElement('link')
      link.id = 'favicon'
      link.rel = 'shortcut icon'
      document.head.appendChild(link)
    }

    link.href = ENV.product_logo
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path='*' element={<Navigate replace to='/login' />} />

              <Route
                exact
                path='/login'
                element={<UnProtectedRoutes component={<Login />} />}
              />
              <Route
                exact
                path='/'
                element={<ProtectedRoutes component={<Dashboard />} />}
              />
              <Route
                exact
                path='/allnotifications'
                element={<ProtectedRoutes component={<AllNotifications />} />}
              />
              <Route
                exact
                path='/alljobs'
                element={<ProtectedRoutes component={<Jobs />} />}
              />
              <Route
                exact
                path='/myjobs'
                element={<ProtectedRoutes component={<MyJobs />} />}
              />
              <Route
                exact
                path='/pendingjobs'
                element={
                  <ProtectedRoutes component={<Jobs status='pending' />} />
                }
              />
              <Route
                exact
                path='/jobdetails'
                element={<ProtectedRoutes component={<JobDetails />} />}
              />
              <Route
                exact
                path='/allapplications'
                element={<ProtectedRoutes component={<Candidates />} />}
              />
              <Route
                exact
                path='/pendingapplications'
                element={
                  <ProtectedRoutes
                    component={<Candidates status={'pendingAtManager'} />}
                  />
                }
              />
              <Route
                exact
                path='/candidatedetails'
                element={<ProtectedRoutes component={<CandidateDetails />} />}
              />
              <Route
                exact
                path='/managetransaction'
                element={<ProtectedRoutes component={<ManageTransaction />} />}
              />
              <Route
                exact
                path='/changePassword'
                element={<ProtectedRoutes component={<ChangePassword />} />}
              />
              <Route
                exact
                path='/createNewUser'
                element={<ProtectedRoutes component={<CreateNewUser />} />}
              />
              <Route
                exact
                path='/manageUsers'
                element={
                  <ProtectedRoutes
                    component={props => <ManageUsers {...props} />}
                  />
                }
              />
              <Route
                exact
                path='/faq'
                element={
                  <ProtectedRoutes component={props => <FAQ {...props} />} />
                }
              />
              <Route
                exact
                path='/createFaq'
                element={<ProtectedRoutes component={<CreateFAQ />} />}
              />

              <Route
                exact
                path='/sendNotification'
                element={<ProtectedRoutes component={<CreateNotification />} />}
              />
              <Route
                exact
                path='/adminuserlist'
                element={
                  <ProtectedRoutes
                    component={props => <AdminUserList {...props} />}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
