import { React, useState } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { isEmpty, validateEmail, validatePassword } from '../utils/AppHelper'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { TextRegular } from '../commonComponents/TextStyling'
import AppButton from '../commonComponents/AppButton'
import serverData from '../api/ServerData'
import { showToast } from '../utils/AppHelper'
import { setUserDetails } from '../utils/LocalStorage'
import PasswordPopperComponent from '../commonComponents/PasswordPopperComponent'
import { useTheme } from '@mui/styles'

function Login (props) {
  const navigate = useNavigate()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState({})

  function handleSubmit (event) {
    event.preventDefault()
    const error = {}

    if (isEmpty(email) || !validateEmail(email)) {
      error.email = 'Invalid email'
    }
    if (isEmpty(password)) {
      error.password = 'Password cannot be empty'
    } else if (password.length < 8) {
      error.password = 'Password should be 8 characters long'
    } else if (!validatePassword(password)) {
      error.password =
        'Password should contain atleast a letter, number and special character'
    }
    setFormError(error)
    if (Object.keys(error).length === 0) {
      setLoading(true)
      serverData.login(
        { email, password },
        result => {
          if (result.status) {
            setUserDetails(result.response)
            navigate('/')
          } else {
            showToast(result?.message)
          }
          setLoading(false)
        },
        error => {
          showToast(error?.statusText)
          setLoading(false)
        }
      )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          width: '40%',
          backgroundColor: theme.palette.background.paper,
          borderRadius: '16px',
          padding: '1rem'
        }}
      >
        <Box component='form' onSubmit={handleSubmit} noValidate>
          <Grid
            item
            xs={12}
            style={{
              margin: '1rem 5rem',
              fontWeight: 600,
              letterSpacing: '0.3px'
            }}
          >
            <TextRegular>Enter your Email ID and password.</TextRegular>
          </Grid>
          <Grid item xs={12} style={{ margin: '1rem 0rem' }}>
            <TextField
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={event => {
                var { value } = event.target
                if (value.length < 50) {
                  setEmail(value)
                }
              }}
              error={formError.email}
              helperText={formError.email}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: '1rem 0rem' }}>
            <PasswordPopperComponent
              password={password}
              setPassword={setPassword}
              formError={formError.password}
              isPopper={false}
              title={'Password'}
            />
          </Grid>

          <Grid item xs={12} style={{ margin: '1rem 0rem' }}>
            <AppButton
              type='submit'
              onClick={handleSubmit}
              name={'Sign in'}
              variant='contained'
              style={{
                height: '50px',
                padding: '12px 24px',
                borderRadius: '8px'
              }}
              disabled={false}
              loading={loading}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}

export default Login
