export const ENV = {
  port: process.env.PORT,

  url: {
    server_base: process.env.REACT_APP_SERVER_BASE_URL,
    websocket_base: process.env.REACT_APP_WEBSOCKET_BASE_URL
  },

  cryptr_key: process.env.REACT_APP_CRYPTR_KEY,

  colors: {
    primary: process.env.REACT_APP_PRIMARY_COLOR,
    primary_text: process.env.REACT_APP_TEXT_PRIMARY_COLOR,
    primary_medium: process.env.REACT_APP_TEXT_PRIMARY_MEDIUM_COLOR,
    primary_small: process.env.REACT_APP_TEXT_PRIMARY_SMALL_COLOR,
    secondary_text: process.env.REACT_APP_TEXT_SECONDARY_COLOR,
    yellow: process.env.REACT_APP_YELLOW_COLOR,
    green: process.env.REACT_APP_GREEN_COLOR,
    red: process.env.REACT_APP_RED_COLOR,
    stroke: process.env.REACT_APP_STROKE_COLOR,
    background: process.env.REACT_APP_BACKGROUND_COLOR,
    paper: process.env.REACT_APP_PAPER_COLOR
  },

  product_name: process.env.REACT_APP_PRODUCT_NAME,
  product_logo: process.env.REACT_APP_PRODUCT_LOGO,
  product_mail: process.env.REACT_APP_PRODUCT_MAIL
}
