import { Button, CircularProgress } from '@mui/material'
import React from 'react'

export default function AppButton ({
  name,
  onClick,
  disabled,
  loading,
  variant = 'contained',
  style = {},
  fullWidth = true,
  type,
  startIcon
}) {
  return (
    <Button
      type={type}
      variant={variant}
      onClick={onClick}
      fullWidth={fullWidth}
      style={{ textTransform: 'none', ...style }}
      disabled={disabled || loading}
      startIcon={loading ? <CircularProgress size={20} /> : startIcon}
    >
      {name}
    </Button>
  )
}
