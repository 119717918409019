import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import AppButton from '../../commonComponents/AppButton'
import serverData from '../../api/ServerData'
import { isEmpty, showToast } from '../../utils/AppHelper'
import { Grid, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions/loginActions'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

export default function RemoveCandidate ({ removeCandidate }) {
  var { open, applicationId, onClose } = removeCandidate
  const [loading, setLoading] = useState(false)
  const [removeReason, setRemoveReason] = useState('')
  const navigate = useNavigate()
  var dispatch = useDispatch()

  const [formError, setFormError] = useState({})

  const validateForm = () => {
    const error = {}

    if (isEmpty(removeReason)) {
      error.removeReason = 'Please enter rejecting reason.'
    }

    setFormError(error)
    return Object.keys(error).length === 0
  }

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true)
      var body = {
        applicationId: applicationId,
        isApproved: false,
        closeReason: removeReason
      }
      serverData.updateCandidateStatus(
        body,
        result => {
          if (result.status) {
            showToast(result.message, false)
            navigate('/pendingapplications')
          } else {
            showToast(result.message)
          }
          setLoading(false)
          onClose()
        },
        error => {
          setLoading(false)
          if (error.status === 401) {
            dispatch(logoutUser(navigate)) // eslint-disable-next-line
          }
        }
      )
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Do you want to reject this candidate?
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Enter candidate rejection reason.'
                  value={removeReason}
                  onChange={event => {
                    setRemoveReason(event.target.value)
                  }}
                  error={formError.removeReason}
                  helperText={formError.removeReason}
                />
              </Grid>
              <Grid item xs={6}>
                <AppButton
                  onClick={handleSubmit}
                  name={'Yes'}
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={false}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <AppButton
                  onClick={onClose}
                  name={'No'}
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={false}
                  loading={false}
                />
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}
