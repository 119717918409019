import React, { useState } from 'react'
import { TextRegular } from './TextStyling'
import { Badge, Divider, useTheme } from '@mui/material'

const TabViewComponent = ({ tabs, style = {} }) => {
  const theme = useTheme()
  const [activeTab, setActiveTab] = useState(tabs[0].id)

  const handleTabClick = tabId => {
    setActiveTab(tabId)
  }

  return (
    <div
      style={{
        width: '100%',
        ...style
      }}
    >
      <div
        style={{
          display: 'flex'
        }}
      >
        {tabs.map((tab, i) => {
          var isActive = tab.id === activeTab
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {i !== 0 && (
                <Divider
                  orientation='vertical'
                  style={{
                    background: theme.palette.text.disabled,
                    width: 2,
                    height: '16px',
                    marginLeft: '24px',
                    marginRight: '24px'
                  }}
                />
              )}
              <TextRegular
                key={tab.id}
                style={{
                  fontSize: '16px',
                  cursor: 'pointer',
                  color: isActive
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled
                }}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TextRegular>
              <Badge
                badgeContent={tab.badge}
                sx={{
                  '& .MuiBadge-badge': { fontSize: 9, width: 15, height: 15 }
                }}
                color='primary'
                style={{ left: 10, top: -8 }}
              />
            </div>
          )
        })}
      </div>
      <div style={{ paddingTop: '24px' }}>
        {tabs.find(tab => tab.id === activeTab).content}
      </div>
    </div>
  )
}

export default TabViewComponent
