import { ENV } from '../ENV'

const Base_Url = ENV.url.server_base
export const SocketUrl = ENV.url.websocket_base

const ApiEndpoint = {
  login: Base_Url + 'admin/login',
  logout: Base_Url + 'admin/logout',
  getjobs: Base_Url + 'admin/getjobs',
  updateJobStatusURL: Base_Url + 'admin/updateJobStatus',
  editJobCommissionDetailsURL: Base_Url + 'admin/editJobCommissionDetails',
  closeJobURL: Base_Url + 'admin/closeJobStatus',
  getCandidateApplications: Base_Url + 'admin/getcandidates',
  updateCandidateStatusURL: Base_Url + 'admin/updateCandidateApplication',
  getPendingTransactions: Base_Url + 'admin/getPendingTransactions',
  updatePaymentStatus: Base_Url + 'admin/updatePaymentStatus',
  changepassword: Base_Url + 'admin/changePassword',
  getAllUsers: Base_Url + 'admin/getAllUsers',
  changeUserStatus: Base_Url + 'admin/changeUserStatus',
  getFaq: Base_Url + 'admin/getallfaqs',
  createFaq: Base_Url + 'admin/createFaq',
  updateFaq: Base_Url + 'admin/updateFaq',
  deleteFaq: Base_Url + 'admin/deleteFaq',
  sendNotification: Base_Url + 'admin/sendNotification',
  getAllAdmins: Base_Url + 'admin/getAllAdmins',
  changeAdminUserStatus: Base_Url + 'admin/changeAdminUserStatus',
  createNewUser: Base_Url + 'admin/createNewUser',
  getNotification: Base_Url + 'admin/getNotification',
  updateNotification: Base_Url + 'admin/updateNotification',
  sendMessage: Base_Url + 'admin/sendMessage',
  markMessageAsSeen: Base_Url + 'admin/markMessageAsSeen',
  editMessage: Base_Url + 'admin/editMessage',
  deleteMessage: Base_Url + 'admin/deleteMessage',
  getdashboard: Base_Url + 'admin/getdashboard',
  getWarnings: Base_Url + 'admin/getWarnings',
  changeAssigneeURL: Base_Url + 'admin/changeAssignee',
  markAsReadNotification: Base_Url + 'admin/markAsReadNotification',
  getNotificationDataURL: Base_Url + 'admin/getNotifications',
  getNotificationsCount: Base_Url + 'admin/getNotificationsCount'
}
export default ApiEndpoint
