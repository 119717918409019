import React, { createRef, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useTheme } from '@mui/material'

export default function TextEditor (props) {
  useEffect(() => {
    const quillEditor = quillRef.current.getEditor()
    const container = quillEditor.container.firstChild
    container.style.minHeight = '150px'
    container.style.maxHeight = '200px'
    container.style.overFlowY = 'auto'
  }, [])

  const theme = useTheme()

  const quillRef = createRef()
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ font: [] }]
    ]
  }
  const formats = [
    'header',
    'font',
    'size',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'align',
    'font'
  ]

  return (
    <ReactQuill
      theme='snow'
      modules={modules}
      formats={formats}
      id='input'
      value={props.getter}
      onChange={content => {
        props.setter(content)
      }}
      ref={quillRef}
      placeholder={props.placeholder}
      style={{
        borderRadius: '8px',
        height: 'auto',
        width: '100%',
        overflowWrap: 'break-word',
        color: theme.palette.text.secondary
      }}
    />
  )
}
