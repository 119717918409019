import CryptoJS from 'crypto-js'
import { ENV } from '../ENV'

export let userToken = ''

export const setUserDetails = async user => {
  userToken = user.authToken

  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(user),
    ENV.cryptr_key
  ).toString()

  localStorage.setItem('user', encryptedData)
}

export const getUserDetails = () => {
  try {
    const encryptedData = localStorage.getItem('user')
    if (!encryptedData) return null
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENV.cryptr_key)
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)

    var user = JSON.parse(decryptedData)

    userToken = user?.authToken || ''
    return user
  } catch (error) {
    clearUserData()
  }
}

export const clearUserData = async () => {
  localStorage.clear()
  return true
}
