import { createTheme } from '@mui/material/styles'
import { colorWithOpacity } from './AppHelper'
import { ENV } from '../ENV'

export const theme = createTheme({
  typography: {
    fontFamily: 'Nunito'
  },
  palette: {
    mode: 'light',
    primary: {
      main: ENV.colors.primary
    },
    background: {
      default: ENV.colors.background,
      paper: ENV.colors.paper
    },
    text: {
      primary: ENV.colors.primary_text,
      secondary: ENV.colors.primary_medium,
      disabled: ENV.colors.primary_small,
      opposite: ENV.colors.secondary_text,
      error: ENV.colors.red,
      success: ENV.colors.green,
      yellow: ENV.colors.yellow
    },
    divider: colorWithOpacity(ENV.colors.primary_text, 0.1),
    border: {
      main: ENV.colors.stroke
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: ENV.colors.primary_text,
            borderRadius: '8px',
            backgroundColor: ENV.colors.paper
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: ENV.colors.paper
        }
      }
    }
  }
})
