export const currencySymbol = '₹'
export const drawerWidth = 245
export const messageEditAllowedMinutes = 30

export const paddingHorizontal = '24px'
export const paddingVertical = '24px'

export const Privileges = {
  level1: ['superadmin'],
  level2: ['superadmin', 'admin'],
  level3: ['superadmin', 'admin', 'manager'],
  level4: ['superadmin', 'admin', 'manager', 'employee']
}

export const ChatOperationCodes = {
  newMessage: 1,
  messageUpdated: 2,
  messageDeleted: 3,
  newJobPosted: 4
}
