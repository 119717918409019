import Server from './callServer'
import ApiEndpoint from './api_endpoints'

const serverData = {
  login: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.login,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getJobs: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getjobs,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  editJobCommissionDetails: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.editJobCommissionDetailsURL,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  updateJobStatus: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.updateJobStatusURL,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  closeJob: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.closeJobURL,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  getApplications: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getCandidateApplications,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  updateCandidateStatus: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.updateCandidateStatusURL,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getPendingTransactions: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getPendingTransactions,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  updatePaymentStatus: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.updatePaymentStatus,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  changePassword: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.changepassword,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getAllUsers: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getAllUsers,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getFaq: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getFaq,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  updateFaq: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.updateFaq,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  createFaq: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.createFaq,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  deleteFaq: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.deleteFaq,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  sendNotification: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.sendNotification,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  changeUserStatus: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.changeUserStatus,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getAllAdmins: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getAllAdmins,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  createNewUser: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.createNewUser,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  changeAdminUserStatus: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.changeAdminUserStatus,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  sendMessage: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.sendMessage,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  markMessageAsSeen: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.markMessageAsSeen,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  editMessage: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.editMessage,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  deleteMessage: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.deleteMessage,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getDashboardData: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getdashboard,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getWarningsAPI: async (body, success, failure) => {
    await Server.getRequest(
      ApiEndpoint.getWarnings,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  logout: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.logout,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },
  changeAssignee: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.changeAssigneeURL,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getNotificationData: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.getNotificationDataURL,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  markAsReadNotification: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.markAsReadNotification,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  },

  getNotificationsCount: async (body, success, failure) => {
    await Server.postRequest(
      ApiEndpoint.getNotificationsCount,
      body,
      result => {
        success(result)
      },
      error => {
        failure(error)
      }
    )
  }
}
export default serverData
