import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/styles'
import {
  TextMedium,
  TextRegular,
  TextSemiBold
} from '../../commonComponents/TextStyling'
import SendIcon from '@mui/icons-material/Send'
import { getUserDetails } from '../../utils/LocalStorage'
import dayjs from 'dayjs'
import { isEmpty, showToast } from '../../utils/AppHelper'
import serverData from '../../api/ServerData'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import WebSocketManager from '../../api/WebSocketManager'
import appassets from '../../utils/appassets'
import AppButton from '../../commonComponents/AppButton'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import {
  ChatOperationCodes,
  messageEditAllowedMinutes
} from '../../utils/AppConstants'

export default function ChatBox ({ item }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [messagesList, setMessagesList] = useState(item.messages || [])
  const latestMessageRef = useRef(messagesList)

  const [loading, setLoading] = useState(false)

  const [message, setMessage] = useState('')
  const [shareWith, setShareWith] = useState(['onlyme', 'recruiter'])

  const user = getUserDetails()

  useEffect(() => {
    latestMessageRef.current = messagesList // Update the ref whenever data changes
  }, [messagesList])

  // WebSocket message handler
  const handleUpdate = useCallback(async event => {
    var { response, opcode } = JSON.parse(event.data)

    if (item.applicationId === response.applicationId) {
      var list = [...latestMessageRef.current]

      var i = list.findIndex(
        e => e.messageId.toString() === response.messageId.toString()
      )
      if (opcode === ChatOperationCodes.messageDeleted) {
        list.splice(i, 1)
        setMessagesList(list)
      } else if (
        opcode === ChatOperationCodes.newMessage ||
        opcode === ChatOperationCodes.messageUpdated
      ) {
        if (i === -1) {
          list = [response, ...list]
          const audio = new Audio(appassets.messageReceivedAudio)
          audio.play()
        } else {
          list[i] = Object.assign(list[i], response)
        }
        setMessagesList(list)
      }
    }
  }, [])

  useEffect(() => {
    WebSocketManager.addEventListener('onmessage', handleUpdate)

    return () => {
      WebSocketManager.removeEventListener('onmessage', handleUpdate)
    }
  }, [])

  function markAsSeen (item, index) {
    serverData.markMessageAsSeen(
      { messageId: item.messageId },
      data => {
        if (data.status) {
          var list = [...messagesList]
          list[index].isSeen = 1
          setMessagesList(list)
        }
      },
      error => {}
    )
  }

  function sendMessage () {
    setLoading(true)

    var body = {
      applicationId: item.applicationId,
      message: message,
      shareWith: shareWith
    }

    serverData.sendMessage(
      body,
      data => {
        if (data.status) {
          setMessage('')
          setMessagesList(prevState => [data.response, ...prevState])
        } else {
          showToast(data.message)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  return (
    <Grid container>
      <Grid item xs={0.7} style={{ alignSelf: 'center' }}>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.border.main}`,
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            overflow: 'hidden',
            padding: '8px',
            alignItems: 'center'
          }}
        >
          <TextRegular
            style={{
              fontSize: '18px'
            }}
          >
            {user.firstname?.substring(0, 1).toUpperCase()}
            {user.lastname?.substring(0, 1).toUpperCase()}
          </TextRegular>
        </Grid>
      </Grid>
      <Grid item xs={11.3} style={{ paddingRight: '12px' }}>
        <TextField
          disabled={loading}
          multiline
          inputProps={{ style: { fontSize: 14 } }}
          placeholder={`Write message here to the recruiter.`}
          required
          fullWidth
          value={message}
          onChange={e => setMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  disabled={loading || isEmpty(message)}
                  onClick={() => sendMessage()}
                  edge='end'
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SendIcon
                      color={isEmpty(message) ? 'disabled' : 'primary'}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      {messagesList.map((item, index) => {
        return (
          <MessageItem
            key={index}
            item={item}
            onMarkSeen={() => markAsSeen(item, index)}
            updateMessageList={(data, type) => {
              var list = [...messagesList]
              if (type === 'delete') {
                list.splice(index, 1)
              } else {
                list[index] = Object.assign(list[index], data)
              }
              setMessagesList(list)
            }}
          />
        )
      })}
    </Grid>
  )
}

function MessageItem ({ item, onMarkSeen, updateMessageList }) {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditEnable, setIsEditEnable] = useState(false)

  item.isSeen = parseInt(item.isSeen)

  const isUnread = item.isMyMessage === 0 && item.isSeen === 0

  const messageRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && isUnread && !loading) {
            setLoading(true)
            setTimeout(async () => {
              await onMarkSeen()
              setLoading(false)
            }, 2000)
          }
        })
      },
      { threshold: 1.0 } // Trigger when the message is fully visible
    )

    if (messageRef.current) {
      observer.observe(messageRef.current)
    }

    // Cleanup observer on component unmount
    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current)
      }
    }
  }, [isUnread])

  let message = item.message

  const wordLimit = 50
  const words = message.split(' ')

  const isLongMessage = words.length >= wordLimit

  if (isLongMessage && !isExpanded) {
    message = words.slice(0, wordLimit).join(' ') + '...'
  }

  return (
    <>
      <Grid item xs={12}>
        <Divider style={{ width: '100%', margin: '16px 0px' }} />
      </Grid>

      <Grid container ref={messageRef}>
        <Grid item xs={0.7} style={{ alignSelf: 'center' }}>
          <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'center',
              border: `1px solid ${
                isUnread
                  ? theme.palette.primary.main
                  : theme.palette.border.main
              }`,
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              overflow: 'hidden',
              padding: '8px',
              alignItems: 'center'
            }}
          >
            <TextRegular
              style={{
                fontSize: '18px'
              }}
            >
              {item?.userFirstName?.substring(0, 1).toUpperCase()}
              {item?.userLastName?.substring(0, 1).toUpperCase()}
            </TextRegular>
          </Grid>
        </Grid>
        <Grid item xs={11.3}>
          <TextRegular>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {isEditEnable ? (
                <EditMessageComponent
                  item={item}
                  updateMessageList={(data, type) => {
                    setIsEditEnable(false)
                    updateMessageList(data, type)
                  }}
                />
              ) : (
                <div style={{ whiteSpace: 'pre-line' }}>
                  {message}
                  {isLongMessage && (
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        cursor: 'pointer',
                        marginLeft: 8
                      }}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {isExpanded ? 'read less' : 'read more'}
                    </span>
                  )}
                </div>
              )}
              {item.isMyMessage === 1 &&
                moment.duration(moment().diff(item.timestamp)).asMinutes() <
                  messageEditAllowedMinutes && (
                  <AppButton
                    name={isEditEnable ? 'Cancel' : 'Edit'}
                    variant='text'
                    fullWidth={false}
                    startIcon={
                      isEditEnable ? (
                        <CloseIcon />
                      ) : (
                        <EditIcon style={{ width: 16, height: 16 }} />
                      )
                    }
                    onClick={() => {
                      setIsEditEnable(!isEditEnable)
                    }}
                  />
                )}
            </div>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: theme.palette.text.secondary,
                paddingRight: '12px'
              }}
            >
              <div>
                {dayjs(item?.timestamp).format('MMM DD, YYYY HH:mm')}{' '}
                <TextSemiBold>
                  {item.shareWith === 'onlyme' ? '(Only me)' : ''}
                </TextSemiBold>
                <TextRegular style={{ fontSize: 12 }}>
                  {item.isEdited === '1' ? '- Edited' : ''}
                </TextRegular>
              </div>
              {item.isMyMessage === 1 ? (
                <TextMedium
                  style={{
                    color: item.isSeen === 1 ? theme.palette.text.success : null
                  }}
                >
                  {item.isSeen === 1 ? 'Seen' : ''}
                </TextMedium>
              ) : (
                isUnread && (
                  <TextMedium
                    style={{
                      display: 'flex',
                      color: theme.palette.primary.main,
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 10,
                        marginRight: 4
                      }}
                    />
                    New
                  </TextMedium>
                )
              )}
            </Grid>
          </TextRegular>
        </Grid>
      </Grid>
    </>
  )
}

function EditMessageComponent ({ item, updateMessageList }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [message, setMessage] = useState(item.message)

  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  function editMessage () {
    setLoading(true)

    var body = {
      messageId: item.messageId,
      message: message
    }

    serverData.editMessage(
      body,
      data => {
        if (data.status) {
          setMessage('')
          updateMessageList(data.response)
        } else {
          showToast(data.message)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  function deleteMessage () {
    setLoadingDelete(true)

    var body = {
      messageId: item.messageId
    }

    serverData.deleteMessage(
      body,
      data => {
        if (data.status) {
          updateMessageList(data.response, 'delete')
        } else {
          showToast(data.message)
        }
        setLoadingDelete(false)
      },
      error => {
        setLoadingDelete(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  return (
    <TextField
      disabled={loading || loadingDelete}
      multiline
      inputProps={{ style: { fontSize: 14 } }}
      placeholder={`Write message here...`}
      required
      fullWidth
      value={message}
      onChange={e => setMessage(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              disabled={loading || loadingDelete}
              onClick={event => setAnchorEl(event.currentTarget)}
              edge='end'
              style={{ marginRight: 8 }}
            >
              {loadingDelete ? (
                <CircularProgress size={24} />
              ) : (
                <DeleteIcon color={'error'} />
              )}
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <div style={{ padding: 16 }}>
                <TextMedium style={{ fontSize: 16 }}>
                  Delete message?
                </TextMedium>
                <div style={{ display: 'flex', marginTop: 8 }}>
                  <AppButton
                    name={'No'}
                    variant='text'
                    style={{ marginRight: 16 }}
                    onClick={() => setAnchorEl(null)}
                  />

                  <AppButton
                    name={'Yes'}
                    variant='text'
                    onClick={() => {
                      setAnchorEl(null)
                      deleteMessage()
                    }}
                  />
                </div>
              </div>
            </Popover>
            <IconButton
              disabled={
                loading ||
                isEmpty(message) ||
                message === item.message ||
                loadingDelete
              }
              onClick={() => editMessage()}
              edge='end'
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <SendIcon
                  color={
                    isEmpty(message) || message === item.message
                      ? 'disabled'
                      : 'primary'
                  }
                />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
