import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, Button, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { TextSemiBold } from './TextStyling'
import { colorWithOpacity, isEmpty } from '../utils/AppHelper'
import * as MaterialIcons from '@mui/icons-material'

const MenuItem = ({ item, user }) => {
  const location = useLocation()
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()
  const handleToggle = () => {
    if (item.submenu) {
      setIsOpen(!isOpen)
    } else {
      navigate(item.link)
    }
  }

  let IconComponent = !isEmpty(item.icon)
    ? MaterialIcons[item.icon]
    : MaterialIcons['QuestionMark']

  return (
    <div>
      <Button
        onClick={handleToggle}
        fullWidth
        variant='text'
        startIcon={IconComponent ? <IconComponent /> : null}
        style={{
          textTransform: 'none',
          padding: '12px 15px',
          justifyContent: 'left',
          color: theme.palette.text.primary,
          fontSize: 16
        }}
      >
        {item.label}
        {item.submenu &&
          (isOpen ? (
            <ArrowDropUpIcon
              style={{ position: 'absolute', right: '15px', top: '10px' }}
            />
          ) : (
            <ArrowDropDownIcon
              style={{ position: 'absolute', right: '15px', top: '10px' }}
            />
          ))}
      </Button>

      {isOpen && item.submenu && (
        <List style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          {item.submenu.map((subItem, index) => {
            let isDisabled = subItem.privilege
              ? !subItem.privilege.includes(user.usertype)
              : false
            return (
              <ListItem key={index} style={{ padding: 0 }}>
                <TextSemiBold
                  onClick={() => (isDisabled ? null : navigate(subItem.link))}
                  style={{
                    fontSize: 16,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    padding: 8,
                    width: '100%',
                    marginBottom: 8,
                    borderRadius: 8,
                    backgroundColor:
                      location.pathname === subItem.link
                        ? colorWithOpacity(theme.palette.primary.main, 0.8)
                        : 'transparent',
                    color: isDisabled
                      ? theme.palette.text.disabled
                      : location.pathname === subItem.link
                      ? theme.palette.text.opposite
                      : theme.palette.text.primary
                  }}
                >
                  {subItem.label}
                </TextSemiBold>
              </ListItem>
            )
          })}
        </List>
      )}
    </div>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    submenu: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    )
  }).isRequired
}

const MenuItems = ({ items, user }) => {
  return (
    <div>
      {items &&
        items.map((item, index) => (
          <MenuItem key={index} item={item} user={user} />
        ))}
    </div>
  )
}

MenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired
        })
      )
    })
  )
}

MenuItems.defaultProps = {
  items: []
}

export default MenuItems
