import React from 'react'
import { ENV } from '../ENV'

export default function AppIcon ({ style }) {
  return (
    <img
      src={`${ENV.product_logo}`}
      alt={'Logo'}
      style={{
        width: '139px',
        height: '62px',
        ...style
      }}
    />
  )
}
