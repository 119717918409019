import { CircularProgress, Grid, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'

import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

import { useLocation, useNavigate } from 'react-router-dom'
import serverData from '../../api/ServerData'
import { checkPrivilege, isEmpty, showToast } from '../../utils/AppHelper'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import dayjs from 'dayjs'
import SearchComponent from '../../commonComponents/SearchComponent'
import AppButton from '../../commonComponents/AppButton'
import { Privileges } from '../../utils/AppConstants'
import { getUserDetails } from '../../utils/LocalStorage'
import AssigneeModal from './AssigneeModal'

export default function MyJobs ({ status }) {
  var user = getUserDetails()
  const [jobData, setJobData] = useState([])
  const [loading, setLoading] = useState(false)

  var dispatch = useDispatch()
  const navigate = useNavigate()

  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)

  const [modal, setModal] = useState({ open: false })

  useEffect(
    function () {
      getMyJobs()
    },
    [status]
  )

  function getMyJobs (searchText) {
    setLoading(true)
    serverData.getJobs(
      { status: status, searchText: searchText, type: 'myjobs' },
      result => {
        if (result.status) {
          setJobData(result.response)
        } else {
          showToast(result.message)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.shade,
      color: theme.palette.common.secondary,
      fontSize: 16,
      fontWeight: 500
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      padding: '8px 15px'
    }
  }))
  const paginatedData = jobData.slice(page * limit, page * limit + limit)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction='column' sx={{ justifyContent: 'end' }}>
          <Grid item style={{ marginBottom: '16px' }}>
            <SearchComponent
              setOnSearch={searchText => {
                getMyJobs(searchText)
              }}
            />
          </Grid>
          {loading ? (
            <Grid
              container
              spacing={2}
              direction={'column'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
                width: '100%'
              }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : jobData?.length > 0 ? (
            <>
              <Grid item>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Job Title</StyledTableCell>
                        <StyledTableCell>Job Type</StyledTableCell>
                        <StyledTableCell>Company Name</StyledTableCell>
                        <StyledTableCell>Location</StyledTableCell>
                        <StyledTableCell>Posted on.</StyledTableCell>
                        <StyledTableCell>Assignee</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        {checkPrivilege(Privileges.level2, user) && (
                          <StyledTableCell>Actions</StyledTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map(row => (
                        <TableRow
                          key={row.id}
                          onClick={() =>
                            navigate('/jobdetails', { state: row })
                          }
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell>{row.jobTitle}</StyledTableCell>
                          <StyledTableCell>{row.jobTypeName}</StyledTableCell>
                          <StyledTableCell>{row.companyname}</StyledTableCell>
                          <StyledTableCell>
                            <>
                              <div>
                                {row?.city}, {row?.state}
                              </div>
                              <div>{row?.countryName}</div>
                            </>
                          </StyledTableCell>
                          <StyledTableCell>
                            {dayjs(row?.createdAt).format('MM/DD/YYYY hh:mm a')}
                          </StyledTableCell>

                          <StyledTableCell>
                            {row.adminFirstName} {row.adminLastName}
                          </StyledTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                textTransform: 'uppercase',
                                color:
                                  row.status === 'pending'
                                    ? '#ff1000'
                                    : '#07bc0c'
                              }}
                            >
                              {row.status}
                            </div>
                          </StyledTableCell>
                          {checkPrivilege(Privileges.level2, user) && (
                            <StyledTableCell>
                              <AppButton
                                onClick={event => {
                                  event.stopPropagation()
                                  setModal({
                                    open: true,
                                    jobId: row.jobId,
                                    managerId: row.managerId,
                                    getAllJobs: getMyJobs,
                                    onClose: () => {
                                      setModal({ open: false })
                                    }
                                  })
                                }}
                                disabled={
                                  isEmpty(row.managerId) ||
                                  row.status !== 'active'
                                }
                                style={{ width: '100px' }}
                                name={'Change Assignee'}
                              />
                            </StyledTableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component='div'
                  count={jobData.length}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={(event, newpage) => {
                    setPage(newpage)
                  }}
                  onRowsPerPageChange={event => {
                    setLimit(parseInt(event.target.value, 10))
                    setPage(0)
                  }}
                />
              </Grid>
            </>
          ) : (
            <NoRecordFound />
          )}
        </Grid>
      </Grid>
      {modal.open && <AssigneeModal data={modal} />}
    </Grid>
  )
}
