import { clearUserData } from '../utils/LocalStorage'
import { showToast } from '../utils/AppHelper'
import serverData from '../api/ServerData'

export function logoutUser (navigate, userLogout = false) {
  return async dispatch => {
    if (userLogout) {
      await serverData.logout(
        {},
        result => {
          if (result.status) {
            showToast(result.message, false)
          }
        },
        error => {}
      )
    }
    clearUserData().then(() => {
      dispatch({
        type: 'CLEAR_APP_STATE',
        payload: {}
      })
      if (!userLogout) {
        showToast('Session expired', true)
      }
      navigate('/login')
    })
  }
}
