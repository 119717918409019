import React, { useEffect, useState } from 'react'

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
  TablePagination
} from '@mui/material'

import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

import { useNavigate } from 'react-router-dom'
import serverData from '../../api/ServerData'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import AppButton from '../../commonComponents/AppButton'

import UpdateFormModal from './UpdateFormModal'
import SearchComponent from '../../commonComponents/SearchComponent'
import { currencySymbol } from '../../utils/AppConstants'
import { formatNumber } from '../../utils/AppHelper'

export default function ManageTransaction () {
  const navigate = useNavigate()
  var dispatch = useDispatch()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)

  const [showModal, setShowModal] = useState({ open: false, data: null })

  useEffect(function () {
    getPendingTransactions()
  }, [])

  function getPendingTransactions (searchText) {
    serverData.getPendingTransactions(
      { searchText },
      result => {
        if (result.status) {
          setData(result.response)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)

        if (error.status === 401) {
          dispatch(logoutUser(navigate))
        }
      }
    )
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.shade,
      color: theme.palette.common.secondary,
      fontSize: 16,
      fontWeight: 500
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      padding: '8px 15px'
    }
  }))

  const paginatedData = data.slice(page * limit, page * limit + limit)

  return (
    <>
      <Grid container xs={12}>
        <Grid container direction='column' sx={{ justifyContent: 'end' }}>
          <Grid item style={{ marginBottom: '16px' }}>
            <SearchComponent
              setOnSearch={searchText => {
                getPendingTransactions(searchText)
              }}
            />
          </Grid>
          {loading ? (
            <Grid
              container
              spacing={2}
              direction={'column'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
                width: '100%'
              }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : data?.length > 0 ? (
            <>
              <Grid item>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Candidate name</StyledTableCell>
                        <StyledTableCell>Candidate email</StyledTableCell>
                        <StyledTableCell>Job Title</StyledTableCell>
                        <StyledTableCell>Company Name</StyledTableCell>
                        <StyledTableCell>Employer amount</StyledTableCell>
                        <StyledTableCell>Recruiter amount</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map(row => (
                        <TableRow key={row.id}>
                          <StyledTableCell>
                            {row.candidateFirstName} {row.candidateLastName}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.candidateEmail}
                          </StyledTableCell>
                          <StyledTableCell>{row.jobTitle}</StyledTableCell>
                          <StyledTableCell>{row.companyname}</StyledTableCell>
                          <StyledTableCell>
                            {currencySymbol}{' '}
                            {formatNumber(row.commissionFromEmployer)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {currencySymbol}{' '}
                            {formatNumber(
                              row.referralRewardType === 'fixed'
                                ? row.referralRewardAmount
                                : row.referralRewardType === 'percentage'
                                ? (row.referralRewardAmount / 100) *
                                  row.baseSalary
                                : row.referralRewardType === 'hourly'
                                ? row.referralRewardAmount
                                : 'NA'
                            )}{' '}
                            ({row.referralRewardType})
                          </StyledTableCell>
                          <StyledTableCell>
                            <AppButton
                              name={
                                !row.employerPaymentDone
                                  ? 'Update Employer Payment'
                                  : 'Update Recruiter Payment'
                              }
                              fullWidth={false}
                              sx={{
                                fontSize: '13px'
                              }}
                              onClick={() => {
                                setShowModal({
                                  open: true,
                                  data: row,
                                  type: !row.employerPaymentDone
                                    ? 'credit'
                                    : 'debit',
                                  onClose: isReload => {
                                    if (isReload) {
                                      getPendingTransactions()
                                    }
                                    setShowModal({ open: false })
                                  }
                                })
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component='div'
                  count={data.length}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={(event, newpage) => {
                    setPage(newpage)
                  }}
                  onRowsPerPageChange={event => {
                    setLimit(parseInt(event.target.value, 10))
                    setPage(0)
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              <NoRecordFound />
            </>
          )}
        </Grid>
      </Grid>
      {showModal.open && (
        <UpdateFormModal
          data={showModal.data}
          onClose={showModal.onClose}
          type={showModal.type}
        />
      )}
    </>
  )
}
