import React from 'react'
import { Navigate } from 'react-router-dom'
import { getUserDetails } from '../utils/LocalStorage'

const UnProtectedRoutes = ({ component }) => {
  var user = getUserDetails()
  if (user) {
    return <Navigate replace to={'/'} />
  }
  return component
}

export default UnProtectedRoutes
