import { default as axios } from 'axios'
import { userToken } from '../utils/LocalStorage'
import { showToast } from '../utils/AppHelper'

const callServer = {
  getRequest: async (url, body, success, failure) => {
    let config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken
      },
      params: body
    }
    await axios
      .get(url, config)
      .then(result => {
        success(result.data)
      })
      .catch(error => {
        failure(error.response || { statusText: error.message }) // If server is down then OR condition will apply with "Network Error"
      })
  },
  postRequest: async (url, body, success, failure, isMultipart = false) => {
    let config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': isMultipart
          ? 'multipart/form-data'
          : 'application/json',
        Authorization: 'Bearer ' + userToken
      }
    }
    await axios
      .post(url, body, config)
      .then(result => {
        success(result.data)
      })
      .catch(error => {
        failure(error.response || { statusText: error.message }) // If server is down then OR condition will apply with "Network Error"
      })
  },

  // Function to download a file
  downloadFile: async (url, filename, onProgress) => {
    try {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob', // Important to handle binary data
        onDownloadProgress: progressEvent => {
          // Calculate the percentage of the download completed
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          if (onProgress) {
            onProgress(percentCompleted) // Call the progress callback
          }
        }
      })

      // Create a URL for the blob object
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      })

      // Create a link element to trigger the download
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename

      // Append to the document and trigger the click event
      document.body.appendChild(link)
      link.click()

      // Clean up
      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)
      onProgress(100)
    } catch (error) {
      console.error('Error downloading file:', error)
      showToast('Error downloading file:', error)
    }
  }
}
export default callServer
