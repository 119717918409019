import React, { useEffect, useState } from 'react'
import { Alert, Grid, Skeleton, useTheme } from '@mui/material'
import * as MaterialIcons from '@mui/icons-material'
import {
  TextBold,
  TextRegular,
  TextSemiBold
} from '../commonComponents/TextStyling'
import { colorWithOpacity, formatNumber, isEmpty } from '../utils/AppHelper'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import serverData from '../api/ServerData'
import { logoutUser } from '../actions/loginActions'

export default function Dashboard () {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [warnings, setWarnings] = useState([])

  useEffect(function () {
    getDashboardData()
    getWarnings()
  }, [])

  function getWarnings () {
    serverData.getWarningsAPI(
      {},
      result => {
        if (result.status) {
          setWarnings(result.response)
        }
      },
      error => {
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  function getDashboardData () {
    serverData.getDashboardData(
      {},
      result => {
        if (result.status) {
          setData(result.response)
        }
      },
      error => {
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <TextBold
              style={{
                fontSize: '24px',
                marginBottom: '8px'
              }}
            >
              Dashboard
            </TextBold>
          </Grid>
          <Grid item xs={12}>
            {warnings.map(item => (
              <Alert severity='error' style={{ width: 'fit-content' }}>
                {item.label}
              </Alert>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {isEmpty(data)
                ? Array.from(Array(4).keys()).map((e, index) => (
                    <Grid item xs={3}>
                      <Skeleton
                        variant='rounded'
                        height={144}
                        style={{
                          borderRadius: '16px',
                          marginLeft: index % 4 === 0 ? 0 : '24px',
                          marginTop: '24px'
                        }}
                      />
                    </Grid>
                  ))
                : data.map((item, index) => {
                    let Icon = !isEmpty(item.icon)
                      ? MaterialIcons[item.icon]
                      : MaterialIcons['QuestionMark']

                    return (
                      <Grid
                        item
                        xs={3}
                        style={{
                          paddingLeft: index % 4 === 0 ? 0 : '24px',
                          paddingTop: '24px',
                          display: 'flex',
                          flex: 1
                        }}
                      >
                        <Grid
                          container
                          style={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '16px',
                            padding: 24
                          }}
                        >
                          <Grid item xs={9}>
                            <Grid container>
                              <Grid item xs={12}>
                                <TextRegular
                                  style={{
                                    color: theme.palette.text.disabled,
                                    fontSize: '16px'
                                  }}
                                >
                                  {item.label}
                                </TextRegular>
                              </Grid>
                              <Grid item xs={12} style={{}}>
                                <TextBold
                                  style={{
                                    fontSize: '28px'
                                  }}
                                >
                                  {formatNumber(item.value)}
                                </TextBold>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              width: '60px',
                              height: '60px',
                              borderRadius: '16px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: colorWithOpacity(
                                theme.palette.primary.main,
                                0.1
                              )
                            }}
                          >
                            <Icon
                              style={{ color: theme.palette.primary.main }}
                            />
                          </Grid>
                          {item?.subItems?.length > 0 && (
                            <Grid container xs={12}>
                              {item.subItems.map(item => (
                                <Grid item xs={12} style={{}}>
                                  <TextRegular
                                    style={{
                                      color: theme.palette.text.disabled
                                    }}
                                  >
                                    {item.label}:{' '}
                                  </TextRegular>
                                  <TextSemiBold>
                                    {formatNumber(item.value)}
                                  </TextSemiBold>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )
                  })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
