import { TextField } from '@mui/material'
import React from 'react'
import InputMask from 'react-input-mask'

export default function PhoneInput (props) {
  return (
    <InputMask
      mask='999-999-9999'
      value={props.value}
      onChange={props.onChange}
    >
      {() => <TextField {...props} placeholder='123-456-7890' />}
    </InputMask>
  )
}
