import { useEffect, useState } from 'react'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import AppButton from '../../commonComponents/AppButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { isEmpty, showToast } from '../../utils/AppHelper'
import TextEditor from '../../commonComponents/TextEditor'
import serverData from '../../api/ServerData'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import { TextBold } from '../../commonComponents/TextStyling'
import { useTheme } from '@mui/styles'

export default function CreateFAQ () {
  const theme = useTheme()
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [faqId, setFaqId] = useState('')
  const [category, setCategory] = useState('')
  const [usertype, setUsertype] = useState('')
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const location = useLocation()
  const data = location.state || {}

  useEffect(function () {
    if (data.isEdit) {
      setFaqId(data.data.id)
      setCategory(data.data.category)
      setAnswer(data.data.answer)
      setQuestion(data.data.question)
      setUsertype(data.data.usertype)
    }
  }, [])

  function validateForm () {
    const error = {}

    if (isEmpty(question)) {
      error.question = 'Question cannot be empty'
    }
    if (isEmpty(answer)) {
      error.answer = 'Answer cannot be empty'
    }
    if (isEmpty(usertype)) {
      error.usertype = 'Please select usertype '
    }
    if (isEmpty(category)) {
      error.category = 'Category cannot be empty'
    }
    setError(error)
    return Object.keys(error).length === 0
  }
  function handleSubmit () {
    if (validateForm()) {
      setLoading(true)
      var body = {
        usertype: usertype,
        question: question,
        answer: answer,
        category: category
      }
      if (data.isEdit) {
        body.id = faqId
      }

      data.isEdit
        ? serverData.updateFaq(
            body,
            result => {
              if (result.status) {
                navigate('/faq')
                showToast(result.message, false)
              } else {
                showToast(result.message)
              }
              setLoading(false)
            },
            error => {
              setLoading(false)
              if (error.status === 401) {
                dispatch(logoutUser(navigate))
              }
            }
          )
        : serverData.createFaq(
            body,
            result => {
              if (result.status) {
                navigate('/faq')
                showToast(result.message, false)
              } else {
                showToast(result.message)
              }
              setLoading(false)
            },
            error => {
              setLoading(false)
              if (error.status === 401) {
                dispatch(logoutUser(navigate))
              }
            }
          )
    }
  }

  return (
    <Grid container spacing={0} style={{ flexDirection: 'column' }}>
      <Grid
        item
        style={{
          background: theme.palette.background.paper,
          padding: '24px',
          borderRadius: '6px'
        }}
      >
        <Grid container spacing={2} onSubmit='handleSubmit' component='form'>
          <Grid item xs={12}>
            {data.isEdit ? (
              <TextBold style={{ fontSize: '18px' }}>Update Faq</TextBold>
            ) : (
              <TextBold style={{ fontSize: '18px' }}>Add FAQ</TextBold>
            )}
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Category'
              required
              type='text'
              id='category'
              name='category'
              value={category}
              onChange={e => {
                var { value } = e.target
                if (value.length < 255) {
                  setCategory(value)
                }
              }}
              error={error.category}
              helperText={error.category}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl error={error.usertype} fullWidth>
              <InputLabel id='usertype'>User</InputLabel>
              <Select
                fullWidth
                label='User'
                id='usertype'
                value={usertype}
                onChange={event => setUsertype(event.target.value)}
              >
                <MenuItem value={'employer'}>Employer</MenuItem>
                <MenuItem value={'recruiter'}>Recruiter</MenuItem>
              </Select>
              <FormHelperText>{error.usertype}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Question'
              required
              type='text'
              id='question'
              name='question'
              value={question}
              onChange={event => {
                setQuestion(event.target.value)
              }}
              error={error.question}
              helperText={error.question}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin='normal'
              error={error.answer}
              style={{ margin: '0px' }}
            >
              <TextEditor
                getter={answer}
                setter={setAnswer}
                placeholder={'Add Answer here'}
              />

              <FormHelperText>{error.answer}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <AppButton
              name={data.isEdit ? 'Update' : 'Submit'}
              style={{
                marginRight: '16px',
                padding: '12px 24px',
                borderRadius: '8px'
              }}
              onClick={handleSubmit}
              fullWidth={false}
              loading={loading}
              disabled={false}
            />

            <AppButton
              name={'Cancel'}
              fullWidth={false}
              onClick={() => navigate('/faq')}
              style={{
                marginRight: '16px',
                padding: '12px 24px',
                borderRadius: '8px'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
