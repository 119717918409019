import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { isEmpty, showToast, validatePassword } from '../utils/AppHelper'
import { useTheme } from '@mui/material'
import AppButton from '../commonComponents/AppButton'
import serverData from '../api/ServerData'
import { TextSemiBold } from '../commonComponents/TextStyling'
import PasswordPopperComponent from '../commonComponents/PasswordPopperComponent'

export default function ChangePassword ({ data }) {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [formError, setFormError] = useState({})

  const validateForm = () => {
    const error = {}

    if (isEmpty(oldPassword)) {
      error.oldPassword = 'Please enter old password.'
    }

    if (isEmpty(newPassword)) {
      error.newPassword = 'Please enter new password.'
    } else if (newPassword.length < 8) {
      error.newPassword = 'Password should be 8 characters long'
    } else if (!validatePassword(newPassword)) {
      error.newPassword =
        'Password should contain atleast a letter, number and special character'
    }

    if (oldPassword === newPassword) {
      error.newPassword = 'Old and New password should not be same.'
    }

    if (isEmpty(confirmPassword)) {
      error.confirmPassword = 'Please enter confirm password.'
    } else if (newPassword !== confirmPassword) {
      error.confirmPassword = 'New and confirm password should be same.'
    }

    setFormError(error)
    return Object.keys(error).length === 0
  }

  const handleChangePassword = async () => {
    if (validateForm()) {
      setLoading(true)
      var body = {
        oldPassword,
        newPassword
      }
      await serverData.changePassword(
        body,
        data => {
          if (data.status) {
            showToast(data.message, false)
            setOldPassword('')
            setNewPassword('')
            setConfirmPassword('')
          } else {
            showToast(data.message)
          }
          setLoading(false)
        },
        error => {
          setLoading(false)
          showToast(error.statusText)
        }
      )
    }
  }
  return (
    <Grid container spacing={0} style={{ flexDirection: 'column' }}>
      <Grid
        item
        style={{
          background: theme.palette.background.paper,
          padding: '24px',
          borderRadius: '6px'
        }}
      >
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextSemiBold style={{ fontSize: '20px' }}>
                Change password
              </TextSemiBold>
            </Grid>
            <Grid item xs={4}>
              <PasswordPopperComponent
                password={oldPassword}
                setPassword={setOldPassword}
                formError={formError.oldPassword}
                isPopper={false}
                title={'Old Password'}
              />
            </Grid>

            <Grid item xs={4}>
              <PasswordPopperComponent
                password={newPassword}
                setPassword={setNewPassword}
                formError={formError.newPassword}
                isPopper={true}
                title={'New password'}
              />
            </Grid>

            <Grid item xs={4}>
              <PasswordPopperComponent
                password={confirmPassword}
                setPassword={setConfirmPassword}
                formError={formError.confirmPassword}
                isPopper={false}
                title={'Confirm Password'}
              />
            </Grid>
          </Grid>
          <Grid spacing={2}>
            <Grid item>
              <AppButton
                variant='contained'
                onClick={() => handleChangePassword()}
                name='Change password'
                disabled={false}
                loading={loading}
                style={{
                  textTransform: 'none',
                  marginTop: '25px',
                  width: 'fit-content'
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
