import * as React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress
} from '@mui/material'

import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import { styled } from '@mui/material/styles'
import AppButton from '../../commonComponents/AppButton'
import { useState } from 'react'
import { useEffect } from 'react'
import serverData from '../../api/ServerData'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../actions/loginActions'
import dayjs from 'dayjs'
import CustomModal from '../../commonComponents/CustomModal'
import { showToast } from '../../utils/AppHelper'
import SearchComponent from '../../commonComponents/SearchComponent'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import UserFilter from './UserFilter'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Privileges } from '../../utils/AppConstants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.shade,
    color: theme.palette.common.secondary,
    fontSize: 16,
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    padding: '8px 15px'
  }
}))

export default function ManageUsers ({ user }) {
  var dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)
  const [openFilterModal, setFilterModal] = useState({
    open: false,
    usertypes: [],
    status: []
  })

  const [modal, setModal] = useState({
    open: false
  })

  useEffect(function () {
    getAllUsers()
  }, [])

  function getAllUsers (searchText) {
    serverData.getAllUsers(
      { searchText },
      result => {
        if (result.status) {
          setUsers(result.response)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  const filteredUsers = users.filter(e => {
    var { usertypes, status } = openFilterModal
    return (
      (usertypes.length > 0 ? usertypes.includes(e.usertype) : true) &&
      (status.length > 0 ? status.includes(e.status) : true)
    )
  })

  const paginatedUsers = filteredUsers.slice(page * limit, page * limit + limit)

  return (
    <Grid container direction='column'>
      <Grid container sx={{ justifyContent: 'right' }}>
        <Grid item xs={11}>
          <SearchComponent
            setOnSearch={searchText => {
              getAllUsers(searchText)
            }}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            justifyContent: 'right',
            display: 'flex',
            paddingLeft: '16px'
          }}
        >
          <AppButton
            name={'Filter'}
            startIcon={<FilterListIcon />}
            onClick={() => {
              setFilterModal(prev => ({
                ...prev,
                open: true,
                onClose: isClear => {
                  var clear = {}
                  if (isClear) {
                    clear = { usertypes: [], status: [] }
                  }
                  setFilterModal(prev => ({
                    ...prev,
                    open: false,
                    ...clear
                  }))
                }
              }))
            }}
          />
        </Grid>
      </Grid>

      {loading ? (
        <Grid
          container
          spacing={2}
          direction={'column'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
            width: '100%'
          }}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : users?.length > 0 ? (
        <>
          <Grid item style={{ marginTop: 24 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>User type</StyledTableCell>
                    <StyledTableCell>Phone</StyledTableCell>
                    <StyledTableCell>Registered On</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedUsers.map((row, index) => (
                    <UsersItem
                      row={row}
                      index={index}
                      setModal={setModal}
                      getAllUsers={getAllUsers}
                      user={user}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component='div'
              count={filteredUsers.length}
              rowsPerPage={limit}
              page={page}
              onPageChange={(event, newpage) => {
                setPage(newpage)
              }}
              onRowsPerPageChange={event => {
                setLimit(parseInt(event.target.value, 10))
                setPage(0)
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          <NoRecordFound />
        </>
      )}
      <CustomModal data={modal} />
      {openFilterModal.open && (
        <UserFilter
          openFilterModal={openFilterModal}
          setFilterModal={setFilterModal}
        />
      )}
    </Grid>
  )
}

function UsersItem ({ row, index, setModal, getAllUsers, user }) {
  var navigate = useNavigate()
  var dispatch = useDispatch()
  const [loadingBtn, setLoadingBtn] = useState(false)

  function changeUserStatus (status) {
    setLoadingBtn(true)
    serverData.changeUserStatus(
      { userId: row.id, status },
      result => {
        if (result.status) {
          showToast(result.message, false)
          getAllUsers()
        } else {
          showToast(result.message)
        }
        setLoadingBtn(false)
      },
      error => {
        setLoadingBtn(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  return (
    <TableRow
      key={index}
      onClick={() => {
        if (row.usertype === 'employer') {
          navigate('/alljobs', { state: { employerId: row.id } })
        } else {
          navigate('/allapplications', { state: { recruiterId: row.id } })
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      <StyledTableCell>
        {row.firstname} {row.lastname}
      </StyledTableCell>
      <StyledTableCell>{row.email}</StyledTableCell>
      <StyledTableCell>{row.usertype}</StyledTableCell>
      <StyledTableCell>
        +{row.countrycode} {row.phone}
      </StyledTableCell>
      <StyledTableCell>
        {dayjs(row.createdAt).format('MM/DD/YYYY hh:mm a')}
      </StyledTableCell>
      <StyledTableCell>{row.status}</StyledTableCell>
      <StyledTableCell>
        <AppButton
          disabled={!Privileges.level3.includes(user.usertype)}
          loading={loadingBtn}
          name={row.status === 'active' ? 'Inactive' : 'Active'}
          style={{ width: '100px', zIndex: 10 }}
          onClick={event => {
            event.stopPropagation()
            setModal({
              open: true,
              message: `Are you sure want to ${
                row.status === 'active' ? 'Inactive' : 'Active'
              } this account?`,
              onClose: () => {
                setModal({ open: false })
              },
              positiveBtnText: 'NO',
              negativeBtnText: 'YES',
              onClickNegativeButton: () => {
                setModal({ open: false })
                changeUserStatus(
                  row.status === 'active' ? 'inactive' : 'active'
                )
              }
            })
          }}
        />
      </StyledTableCell>
    </TableRow>
  )
}
