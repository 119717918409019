import { Badge, CircularProgress, Divider, Grid, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextBold, TextRegular } from '../../commonComponents/TextStyling'
import serverData from '../../api/ServerData'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import { isEmptyJson } from '../../utils/AppHelper'
import moment from 'moment'
import TabViewComponent from '../../commonComponents/TabViewComponent'
import SearchComponent from '../../commonComponents/SearchComponent'

export default function AllNotifications () {
  const theme = useTheme()
  const location = useLocation()

  const [notificationData, setNotificationData] = useState({
    data: [],
    unreadCount: 0
  })
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(function () {
    getNotification()
  }, [])

  function getNotification (searchText) {
    setLoading(false)
    serverData.getNotificationData(
      { searchText },
      result => {
        if (result.status) {
          var { response } = result
          setNotificationData(response)
          if (response.data.length > 0) {
            const notification = location.state || {}
            handleSelect(
              isEmptyJson(notification) ? response.data[0] : notification,
              response
            )
          }
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  function markAsRead (id, notificationData) {
    serverData.markAsReadNotification(
      { adminNotificationId: id },
      result => {
        if (result.status) {
          var data = { ...notificationData }
          var index = data.data.findIndex(e => e.adminNotificationId === id)
          if (index !== -1) {
            data.data[index].isRead = 1
            data.unreadCount--
            setNotificationData(data)
          }
        }
      },
      error => {
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  function handleSelect (item, data) {
    if (item.isRead === 0) {
      markAsRead(item.adminNotificationId, data || notificationData)
    }
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex'
        }}
      >
        <Grid item xs={12}>
          <SearchComponent
            setOnSearch={searchText => {
              getNotification(searchText)
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: '16px',
          marginTop: '24px'
        }}
      >
        <Grid item xs={12} style={{ padding: '24px 0px 0px 24px' }}>
          <TextBold style={{ fontSize: 20 }}>All Notifications</TextBold>
          <Badge
            badgeContent={notificationData.unreadCount}
            color='primary'
            style={{ left: 14, top: -5 }}
          />
        </Grid>
        <Divider style={{ width: '100%', marginTop: '24px' }} />
        {loading ? (
          <Grid
            container
            spacing={2}
            direction={'column'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
              width: '100%'
            }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <TabViewComponent
            style={{
              padding: '24px'
            }}
            tabs={[
              {
                id: 0,
                label: 'All',
                content: (
                  <NotificationsContent
                    data={notificationData.data}
                    theme={theme}
                    onClick={handleSelect}
                  />
                )
              },
              {
                id: 1,
                label: 'Unread',
                content: (
                  <NotificationsContent
                    data={notificationData.data.filter(e => e.isRead === 0)}
                    theme={theme}
                    onClick={handleSelect}
                  />
                )
              }
            ]}
          />
        )}
      </Grid>
    </Grid>
  )
}

function NotificationsContent ({ data, theme, onClick }) {
  return data.length > 0 ? (
    data.map((item, index) => {
      let isUnread = item.isRead === 0
      return (
        <div
          key={index}
          style={{ cursor: item.isRead === 0 ? 'pointer' : 'default' }}
          onClick={() => {
            onClick(item)
          }}
        >
          {index !== 0 && (
            <Divider style={{ width: '100%', margin: '16px 0px' }} />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex'
              }}
            >
              {isUnread && (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 10,
                    marginTop: 6,
                    marginRight: 14
                  }}
                />
              )}
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                <TextBold
                  style={{
                    fontSize: 16,
                    color:
                      item.isRead === 0
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                  }}
                >
                  {item.title}
                </TextBold>

                <TextRegular
                  style={{
                    color:
                      item.isRead === 0
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.message
                      .replace(/<br\s*\/?>/gi, ' ')
                      .replace(/<p([^>]*)>/g, '<div$1>')
                      .replace(/<\/p>/g, '</div>')
                  }}
                />
              </div>
            </div>
            <TextRegular
              style={{
                color:
                  item.isRead === 0
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled
              }}
            >
              {moment(item?.createdAt).fromNow()}
            </TextRegular>
          </div>
        </div>
      )
    })
  ) : (
    <NoRecordFound />
  )
}
