import React, { useState } from 'react'
import { Grid, Box, TextField } from '@mui/material'
import { TextSemiBold } from '../../commonComponents/TextStyling'
import AppButton from '../../commonComponents/AppButton'

import Modal from '@mui/material/Modal'
import { isEmpty, showToast, validateOnlyDigit } from '../../utils/AppHelper'
import serverData from '../../api/ServerData'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions/loginActions'

export default function UpdateFormModal ({ data, onClose, type }) {
  const navigate = useNavigate()
  var dispatch = useDispatch()

  const [error, setError] = useState({})
  const [amount, setAmount] = useState('')
  const [transactioID, setTransactionID] = useState('')
  const [loading, setLoading] = useState(false)

  function validateForm () {
    const error = {}

    if (isEmpty(amount)) {
      error.amount = 'Amount cannot be empty'
    }
    if (isEmpty(transactioID)) {
      error.transactioID = 'Transaction ID cannot be empty'
    }
    setError(error)

    return Object.keys(error).length === 0
  }

  function handleSubmit (event) {
    event.preventDefault()

    if (validateForm()) {
      setLoading(true)
      var body = {
        applicationId: data.applicationId,
        amount: amount,
        referenceId: transactioID,
        type: type
      }

      serverData.updatePaymentStatus(
        body,
        result => {
          if (result.status) {
            showToast(result?.message, false)
            onClose(true)
          } else {
            showToast(result?.message)
          }
          setLoading(false)
        },
        error => {
          setLoading(false)
          if (error.status === 401) {
            dispatch(logoutUser(navigate)) // eslint-disable-next-line
          }
        }
      )
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 5,
    p: 3
  }

  return (
    <Modal open={true} onClose={() => onClose()}>
      <Box sx={style} onSubmit={handleSubmit} noValidate>
        <Grid sx={{ mb: 3 }}>
          <TextSemiBold sx={{ fontSize: '18px' }}>Update Payment</TextSemiBold>
        </Grid>
        <Grid container sx={{ mb: 4 }}>
          <Grid xs={6}>
            <TextField
              margin='normal'
              required
              id='amount'
              name='amount'
              value={amount}
              label='Enter Amount'
              sx={{ mr: 2 }}
              onChange={e => {
                var { value } = e.target
                if (value.length < 11) {
                  if (validateOnlyDigit(value)) {
                    setAmount(e.target.value)
                  }
                }
              }}
              error={error.amount}
              helperText={error.amount}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              margin='normal'
              required
              id='transactioID'
              name='transactioID'
              value={transactioID}
              label='Enter Transaction ID'
              onChange={e => {
                var { value } = e.target
                if (value.length < 500) {
                  setTransactionID(e.target.value)
                }
              }}
              error={error.transactioID}
              helperText={error.transactioID}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sx={{ mr: 2 }}>
            <AppButton
              loading={loading}
              sx={{ mr: 2 }}
              name={'Submit'}
              onClick={handleSubmit}
            />
          </Grid>
          <Grid item>
            <AppButton
              disabled={loading}
              name={'Cancel'}
              onClick={() => onClose()}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
