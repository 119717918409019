import React, { useState } from 'react'
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Divider
} from '@mui/material'
import ChangePwd from '@mui/icons-material/LockOpen'
import { useNavigate } from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import Menu from '@mui/material/Menu'
import { useTheme } from '@mui/material/styles'

import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout'
import { useDispatch } from 'react-redux'
import {
  TextHover,
  TextMedium,
  TextRegular
} from '../../src/commonComponents/TextStyling'
import { logoutUser } from '../actions/loginActions'
import { Privileges, drawerWidth } from '../utils/AppConstants'
import AppIcon from './AppIcon'
import MenuItems from './Menu'
import { ENV } from '../ENV'
import NotificationsIcon from './NotificationsIcon'

const menuData = [
  { label: 'Dashboard', icon: 'HomeRepairServiceOutlined', link: '/ ' },
  {
    label: 'Jobs',
    icon: 'WorkOutline',
    submenu: [
      { label: 'All Jobs', link: '/alljobs' },
      { label: 'Review Jobs', link: '/pendingjobs' },
      { label: 'My Jobs', link: '/myjobs' }
    ]
  },
  {
    label: 'Candidate',
    icon: 'PersonOutline',
    submenu: [
      { label: 'All Application', link: '/allapplications' },
      { label: 'Review Application', link: '/pendingapplications' },
      { label: 'Manage Transaction', link: '/manageTransaction' }
    ]
  },
  {
    label: 'User',
    icon: 'ManageAccountsOutlined',
    submenu: [{ label: 'User List', link: '/manageUsers' }]
  },
  {
    label: 'Admin',
    icon: 'AdminPanelSettingsOutlined',
    submenu: [
      { label: 'Users list', link: '/adminuserlist' },
      { label: 'FAQ', link: '/faq' },
      {
        label: 'Send notification',
        link: '/sendNotification',
        privilege: Privileges.level3
      }
      // { label: 'All Notifications', link: '/allnotification' }
    ]
  }
]

function DrawerMenu ({ user }) {
  const theme = useTheme()

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)

  const navigate = useNavigate()

  // profile open
  const handleMouseEnter = event => {
    setAnchorEl(event.currentTarget)
    setOpenMenu(true)
  }

  // profile close
  const handleMouseLeave = () => {
    setOpenMenu(false)
  }

  return (
    <div>
      <AppBar
        sx={{
          borderBottom: `1px solid ${theme.palette.border.main}`
        }}
        elevation={0}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant='h6' noWrap>
            {ENV.product_name}
          </Typography>

          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <NotificationsIcon />

            <div
              onClick={handleMouseEnter}
              style={{ display: 'flex', cursor: 'pointer' }}
              aria-controls={openMenu ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openMenu ? 'true' : undefined}
            >
              <Avatar
                sx={{
                  width: 28,
                  height: 28
                }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0px 10px',
                  lineHeight: 1
                }}
              >
                <TextMedium>
                  {user.firstname} {user.lastname}
                </TextMedium>

                <TextRegular
                  style={{ fontSize: 12, textTransform: 'capitalize' }}
                >
                  ({user.usertype})
                </TextRegular>
              </div>

              <KeyboardArrowDownIcon />
            </div>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={openMenu}
              onClose={handleMouseLeave}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'hidden',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 6,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem
                style={{ fontSize: 14, fontWeight: 500, width: '180px' }}
                onClick={() => {
                  handleMouseLeave()
                  navigate('/changePassword') // eslint-disable-next-line
                }}
              >
                <TextHover style={{ display: 'flex' }}>
                  <ListItemIcon>
                    <ChangePwd fontSize='small' />
                  </ListItemIcon>
                  Change Password
                </TextHover>
              </MenuItem>
              <MenuItem
                style={{ fontSize: 14, fontWeight: 500, width: '180px' }}
                onClick={() => {
                  handleMouseLeave()
                  dispatch(logoutUser(navigate, true)) // eslint-disable-next-line
                }}
              >
                <TextHover style={{ display: 'flex' }}>
                  <ListItemIcon>
                    <Logout fontSize='small' />
                  </ListItemIcon>
                  Logout
                </TextHover>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant='permanent'
        anchor='left'
        open={true}
        PaperProps={{
          sx: {
            width: drawerWidth,
            backgroundColor: theme.palette.background.paper
          }
        }}
      >
        <AppIcon style={{ padding: '8px 0px 8px 16px' }} />
        <Divider />
        <MenuItems items={menuData} user={user} />
      </Drawer>
    </div>
  )
}

export default DrawerMenu
