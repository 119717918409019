import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  TextBold,
  TextRegular,
  TextSemiBold
} from '../../commonComponents/TextStyling'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import serverData from '../../api/ServerData'

import { Box, Grid, Button } from '@mui/material'
import AppButton from '../../commonComponents/AppButton'
import { isEmpty, showToast } from '../../utils/AppHelper'
import RemoveCandidate from './RemoveCandidate'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions/loginActions'
import dayjs from 'dayjs'
import callServer from '../../api/callServer'
import Divider from '@mui/material/Divider'
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import ChatBox from './ChatBox'
import CustomModal from '../../commonComponents/CustomModal'

export const useStyle = makeStyles({
  heading: {
    fontWeight: 700
  },
  answer: {
    fontWeight: 300
  }
})

export default function CandidateDetails (props) {
  const location = useLocation()
  const row = location.state || {}
  var dispatch = useDispatch()
  var navigate = useNavigate()
  const [progress, setProgress] = useState(0)
  const [progressCover, setProgressCover] = useState(0)

  var theme = useTheme()
  const [loading, setLoading] = useState(false)

  const [viewRemoveModal, setViewRemoveModal] = useState({
    open: false
  })

  const [modal, setModal] = useState({
    open: false
  })

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px'
  }

  const handleClick = () => {
    setModal({
      open: true,
      message: `Are you sure want to approve this job?`,
      onClose: () => {
        setModal({ open: false })
      },
      positiveBtnText: 'YES',
      onClickPositiveButton: () => {
        setLoading(true)
        serverData.updateCandidateStatus(
          {
            applicationId: row.applicationId,
            isApproved: true,
            closeReason: ''
          },
          result => {
            if (result.status) {
              showToast(result.message, false)
              navigate('/pendingapplications')
            } else {
              showToast(result.message)
            }
            setLoading(false)
          },
          error => {
            setLoading(false)
            if (error.status === 401) {
              dispatch(logoutUser(navigate)) // eslint-disable-next-line
            }
          }
        )
      },
      negativeBtnText: 'NO',
      onClickNegativeButton: () => {
        setModal({ open: false })
      }
    })
  }

  const Status = [
    {
      title: 'Status',
      value: row.status.charAt(0).toUpperCase() + row.status.slice(1)
    },
    {
      title: 'Close Reason',
      value: row.closeReason
    },
    {
      title: 'Sub status',
      value: row.subStatus
    },
    { title: 'Guarantee Period', value: row.guaranteePeriod },
    {
      title: 'Is Joined',
      value: isEmpty(row.isJoined) ? null : row.isJoined === 1 ? (
        <>Joined</>
      ) : (
        <>Not joined</>
      )
    },
    { title: 'Base Salary', value: row.baseSalary }
  ]
  const Activities = [
    {
      title: 'Manager Review',
      value: row.managerReviewDate
        ? dayjs(row.managerReviewDate).format('MM/DD/YYYY hh:mm a')
        : null
    },
    {
      title: 'Interview Date',
      value: row.interviewStageDate
        ? dayjs(row.interviewStageDate).format('MM/DD/YYYY hh:mm a')
        : null
    },
    {
      title: 'Offer Letter Date',
      value: row.offerStageDate
        ? dayjs(row.offerStageDate).format('MM/DD/YYYY hh:mm a')
        : null
    },
    {
      title: 'Hiring Date',
      value: row.hiredOnDate
        ? dayjs(row.hiredOnDate).format('MM/DD/YYYY hh:mm a')
        : null
    },
    {
      title: 'Rejection Date',
      value: row.rejectedOnDate
        ? dayjs(row.rejectedOnDate).format('MM/DD/YYYY hh:mm a')
        : null
    },
    {
      title: 'Joining Date',
      value: row.joiningDate
        ? dayjs(row.joiningDate).format('MM/DD/YYYY')
        : null
    },
    {
      title: 'Left-out Date',
      value: row.leftOutDate
        ? dayjs(row.leftOutDate).format('MM/DD/YYYY')
        : null
    },
    {
      title: 'Payment Status',
      value:
        row.paymentStatus.charAt(0).toUpperCase() + row.paymentStatus.slice(1)
    },
    { title: 'Transaction Id', value: row.transactionId }
  ]

  return (
    <Grid container containerspacing={2}>
      <Grid
        container
        style={{
          padding: '16px',
          borderRadius: '6px',
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Grid item>
          <Grid
            conatiner
            spacing={2}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item style={{ display: 'flex' }}>
              <PermContactCalendarOutlinedIcon />
              <Grid
                conatiner
                style={{ flexDirection: 'column', marginLeft: '15px' }}
              >
                {row.candidateFirstName} {row.candidateLastName}
                <Grid
                  container
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <TextRegular> {row.candidateEmail}</TextRegular>
                  <TextRegular
                    style={{
                      fontSize: '12px',
                      margin: '0px 12px',
                      color: theme.palette.text.grey
                    }}
                  >
                    |
                  </TextRegular>
                  <TextRegular> {row.candidatePhone}</TextRegular>
                </Grid>
              </Grid>
            </Grid>
            <AppButton
              name={
                progress > 0 ? `Downloading: ${progress}%` : 'Download resume'
              }
              fullWidth={false}
              onClick={() => {
                let file = row?.resumePath
                let fileName = `${row?.candidateFirstName}${
                  row?.candidateLastName
                }Resume.${file.split('.').pop()}`
                callServer.downloadFile(file, fileName, percentCompleted => {
                  setProgress(percentCompleted === 100 ? 0 : percentCompleted)
                })
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {row?.coverSummary !== null && (
              <>
                <Grid>
                  <MarkAsUnreadOutlinedIcon />
                  <TextRegular style={{ marginLeft: '15px' }}>
                    Cover letter:
                  </TextRegular>
                </Grid>
                <Box
                  style={{
                    marginLeft: '40px',
                    marginTop: '-10px',
                    fontSize: '13px',
                    lineHeight: 'normal'
                  }}
                  dangerouslySetInnerHTML={{
                    __html: row?.coverSummary
                  }}
                />
              </>
            )}
          </Grid>

          <Grid item xs={3}>
            {row?.coverLetterPath && (
              <AppButton
                name={
                  progressCover > 0
                    ? `Downloading: ${progressCover}%`
                    : 'Download cover letter'
                }
                fullWidth={false}
                onClick={() => {
                  let file = row.coverLetterPath
                  let fileName = `${row?.candidateFirstName}${
                    row?.candidateLastName
                  }Cover.${file.split('.').pop()}`
                  callServer.downloadFile(file, fileName, percentCompleted => {
                    setProgressCover(
                      percentCompleted === 100 ? 0 : percentCompleted
                    )
                  })
                }}
              />
            )}
          </Grid>
          <Divider sx={{ margin: '15px 0' }} />
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <WorkOutlineOutlinedIcon />
              <TextRegular
                style={{
                  fontSize: '16px',
                  marginLeft: '15px'
                }}
              >
                Job details
              </TextRegular>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item style={containerStyle}>
                <TextBold>Job Title: </TextBold>
                <TextBold> {row?.jobTitle}</TextBold>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Job Industry:</TextRegular>
                <TextRegular> {row.jobIndustryName}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Job Function: </TextRegular>
                <TextRegular> {row.jobFunctionName}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Company:</TextRegular>
                <TextRegular> {row.companyname}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Education Level: </TextRegular>
                <TextRegular> {row.educationLevelName}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Experience Level:</TextRegular>
                <TextRegular> {row.experienceLevelName}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Rejection period:</TextRegular>
                <TextRegular>
                  {' '}
                  {row?.requiredExpYear} years {row?.requiredExpMonth} months
                </TextRegular>
              </Grid>
              {/* <Grid item style={containerStyle}>
                <TextRegular>Visa Sponsorship:</TextRegular>
                <TextRegular> {row?.visaSponsorship}</TextRegular>
              </Grid> */}
              <Grid item style={containerStyle}>
                <TextRegular>Job Type:</TextRegular>
                <TextRegular> {row?.jobTypeName}</TextRegular>
              </Grid>
              {row?.jobType === '1' && (
                <>
                  <Grid item style={containerStyle}>
                    <TextRegular>Contract Duration:</TextRegular>
                    <TextRegular>
                      {' '}
                      {row?.contractDuration} {row?.durationType}
                    </TextRegular>
                  </Grid>
                  <Grid item style={containerStyle}>
                    <TextRegular>Contract Start Date:</TextRegular>
                    <TextRegular>
                      {' '}
                      {dayjs(row?.desiredStartDate).format(
                        'MM/DD/YYYY hh:mm a'
                      )}
                    </TextRegular>
                  </Grid>
                  <Grid item style={containerStyle}>
                    <TextRegular>
                      Candidate submission limit per supplier:
                    </TextRegular>
                    <TextRegular> {row?.supplierLimit}</TextRegular>
                  </Grid>
                  <Grid item style={containerStyle}>
                    <TextRegular>Additional Compensation:</TextRegular>
                    <TextRegular> {row?.jobBenefits}</TextRegular>
                  </Grid>
                </>
              )}

              {(row?.jobType === '2' ||
                row?.jobType === '3' ||
                row?.jobType === '4') && (
                <>
                  <Grid item style={containerStyle}>
                    <TextRegular>Compensation Amount:</TextRegular>
                    <TextRegular>
                      {' '}
                      {row?.jobCompensationAmountMin} -{' '}
                      {row?.jobCompensationAmountMax}
                    </TextRegular>
                  </Grid>
                  {row?.jobCompensationText !== '' && (
                    <Grid item style={containerStyle}>
                      <TextRegular>Compensation:</TextRegular>
                      <TextRegular> {row?.jobCompensationText}</TextRegular>
                    </Grid>
                  )}
                </>
              )}

              {row?.jobType === '5' && (
                <>
                  <Grid item style={containerStyle}>
                    <TextRegular>Rejection period:</TextRegular>
                    <TextRegular> {row?.rejectionPeriod}</TextRegular>
                  </Grid>
                  <Grid item style={containerStyle}>
                    <TextRegular>Rejection reason:</TextRegular>
                    {row?.rejectionReason?.map((queAns, index) => (
                      <Grid
                        key={index}
                        item
                        style={{ display: 'flex', lineHeight: '2rem' }}
                      >
                        <TextRegular>
                          {index + 1}. {queAns?.reason}
                        </TextRegular>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              <Grid item style={containerStyle}>
                <TextRegular>Total Position:</TextRegular>
                <TextRegular> {row?.totalPositions}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Close Position:</TextRegular>
                <TextRegular> {row?.closedPositions}</TextRegular>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ margin: '15px 0' }} />
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <ShareLocationOutlinedIcon />
              <TextRegular
                style={{
                  fontSize: '16px',
                  marginLeft: '15px'
                }}
              >
                Location
              </TextRegular>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item style={containerStyle}>
                <TextRegular>State:</TextRegular>
                <TextRegular> {row.state}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>City:</TextRegular>
                <TextRegular> {row?.city}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Country:</TextRegular>
                <TextRegular>{row.countryName}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Zip code:</TextRegular>
                <TextRegular> {row.zipCode}</TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Relocation Assistance:</TextRegular>
                <TextRegular>
                  <>
                    <div>{row?.relocationAssistanceName}</div>
                    <div>{row?.jobRelocationAssistanceText}</div>
                  </>
                </TextRegular>
              </Grid>
              <Grid item style={containerStyle}>
                <TextRegular>Work Location:</TextRegular>
                <TextRegular>
                  <>
                    <div>{row?.workLocationTypeName}</div>
                    <div>{row?.workLocationInfo}</div>
                  </>
                </TextRegular>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ margin: '15px 0' }} />
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DescriptionOutlinedIcon />
              <TextRegular
                style={{
                  fontSize: '16px',
                  marginLeft: '15px'
                }}
              >
                Job description
              </TextRegular>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item style={containerStyle}>
                <TextRegular>Skills:</TextRegular>
                <TextRegular>
                  {' '}
                  {row.skillRequired
                    .split(',')
                    .map(skill => skill.trim())
                    .map(
                      skill => skill.charAt(0).toUpperCase() + skill.slice(1)
                    )
                    .join(', ')}
                </TextRegular>
              </Grid>

              <Grid item style={containerStyle}>
                <TextRegular>Job Description:</TextRegular>
                <TextRegular>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: row.jobDescription
                    }}
                  />
                </TextRegular>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ margin: '15px 0' }} />
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ContactSupportOutlinedIcon />
                <TextRegular
                  style={{
                    fontSize: '16px',
                    marginLeft: '15px'
                  }}
                >
                  Pre Screen Questions:
                </TextRegular>
              </Grid>
              {row?.preScreenQuestions?.map((queAns, index) => (
                <>
                  <Grid
                    key={index}
                    item
                    xs={12}
                    style={{ display: 'flex', lineHeight: '2rem' }}
                  >
                    <TextSemiBold>
                      {index + 1}. {queAns?.question}
                    </TextSemiBold>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '10px',
                      width: '75%'
                    }}
                  >
                    <TextRegular>
                      Ans.{' '}
                      {queAns?.type !== 'textfield'
                        ? queAns.answer.replaceAll('::', ',')
                        : queAns?.answer}
                    </TextRegular>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
          <Divider sx={{ margin: '15px 0' }} />

          <Grid container>
            <Grid item xs={12}>
              <TextBold style={{ marginLeft: '15px' }}>
                Job Application Status
              </TextBold>
            </Grid>

            {Status.map((item, index) => (
              <>
                {' '}
                {item.value !== null && (
                  <Grid item xs={4} key={index}>
                    <TextRegular>{item.title}:</TextRegular>
                    <TextSemiBold>
                      {' '}
                      {item.type === 'date'
                        ? dayjs(item?.value).format('MM/DD/YYYY hh:mm a')
                        : item.value}
                    </TextSemiBold>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <Divider sx={{ margin: '15px 0' }} />
          <Grid container>
            <Grid item xs={12}>
              <TextBold style={{ marginLeft: '15px' }}>Activities:</TextBold>
            </Grid>
            {Activities.map((item, index) => (
              <>
                {item.value !== null && (
                  <Grid item xs={4} key={index}>
                    <TextRegular>{item.title}: </TextRegular>
                    <TextSemiBold>{item.value}</TextSemiBold>
                  </Grid>
                )}
              </>
            ))}
          </Grid>

          <Divider sx={{ margin: '15px 0' }} />
          <Grid container>
            <Grid item xs={12}>
              <TextBold style={{ marginLeft: '15px' }}>
                Chat with recruiters:
              </TextBold>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: '16px',
                maxHeight: '50vh',
                overflowY: 'auto'
              }}
            >
              <ChatBox item={row} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {row.status === 'pendingAtManager' && (
        <Grid item style={{ marginTop: '20px' }}>
          <Grid item spacing={2}>
            <AppButton
              type='submit'
              onClick={handleClick}
              name={'Approve'}
              variant='contained'
              style={{ marginRight: '15px' }}
              disabled={false}
              fullWidth={false}
              loading={loading}
            />

            <Button
              variant='outlined'
              fullWidth={false}
              style={{ textTransform: 'none' }}
              onClick={() => {
                setViewRemoveModal({
                  open: true,
                  applicationId: row.applicationId,
                  onClose: () => {
                    setViewRemoveModal({
                      open: false
                    })
                  }
                })
              }}
            >
              Reject
            </Button>
          </Grid>
        </Grid>
      )}
      {viewRemoveModal?.open && (
        <RemoveCandidate removeCandidate={viewRemoveModal} />
      )}
      <CustomModal data={modal} />
    </Grid>
  )
}
