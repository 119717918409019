import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Modal
} from '@mui/material'
import React from 'react'
import AppButton from '../../commonComponents/AppButton'
import { TextBold, TextSemiBold } from '../../commonComponents/TextStyling'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 5,
  p: 4,
  width: 550
}

export default function UserFilter ({ openFilterModal, setFilterModal }) {
  var { open, onClose, usertypes, status } = openFilterModal
  const filtersOptions = [
    {
      label: 'User type',
      options: [
        { label: 'Recruiter', value: 'recruiter' },
        { label: 'Employer', value: 'employer' }
      ],
      keyname: 'usertypes',
      value: usertypes
    },
    {
      label: 'Status',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
      ],
      keyname: 'status',
      value: status
    }
  ]
  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <TextBold style={{ fontSize: '24px' }}>Apply filter</TextBold>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', justifyContent: 'right' }}
          >
            <AppButton
              name={'Clear'}
              variant='text'
              fullWidth={false}
              onClick={() => onClose(true)}
            />
          </Grid>
          {[
            filtersOptions.map(item => (
              <>
                <Grid item xs={12}>
                  <Divider style={{ margin: '16px 0px' }} />
                </Grid>

                <Grid item xs={12}>
                  <TextSemiBold style={{ fontSize: '16px' }}>
                    {item.label}:
                  </TextSemiBold>
                </Grid>
                <Grid container xs={12}>
                  {item.options.map(option => {
                    var checkedIndex = item.value.findIndex(
                      e => e === option.value
                    )
                    return (
                      <Grid
                        item
                        xs={4}
                        style={{
                          textTransform: 'capitalize'
                        }}
                      >
                        <FormControlLabel
                          key={option.value}
                          label={option.label}
                          control={
                            <Checkbox
                              checked={checkedIndex !== -1}
                              onChange={() => {
                                var data = { ...openFilterModal }
                                if (checkedIndex !== -1) {
                                  data[item.keyname].splice(checkedIndex, 1)
                                } else {
                                  data[item.keyname].push(option.value)
                                }
                                setFilterModal(data)
                              }}
                            />
                          }
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            ))
          ]}
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 16
          }}
        >
          <AppButton name={'OK'} fullWidth={false} onClick={() => onClose()} />
        </div>
      </Box>
    </Modal>
  )
}
