import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress
} from '@mui/material'

import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import { styled } from '@mui/material/styles'

import serverData from '../../api/ServerData'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../actions/loginActions'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import AppButton from '../../commonComponents/AppButton'
import { Privileges } from '../../utils/AppConstants'
import { checkPrivilege, showToast } from '../../utils/AppHelper'
import CustomModal from '../../commonComponents/CustomModal'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.shade,
    color: theme.palette.common.secondary,
    fontSize: 16,
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    padding: '8px 15px'
  }
}))

export default function AdminUserList ({ user }) {
  var dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)

  const [modal, setModal] = useState({
    open: false
  })

  useEffect(function () {
    getAllAdmins()
  }, [])

  function getAllAdmins () {
    serverData.getAllAdmins(
      {},
      result => {
        if (result.status) {
          setUsers(result.response)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  const paginatedUsers = users.slice(page * limit, page * limit + limit)

  return (
    <Grid container direction='column'>
      {checkPrivilege(Privileges.level2, user) && (
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'right',
            width: '100%'
          }}
        >
          <AppButton
            name={'Create New user'}
            onClick={() => navigate('/createNewUser')}
            fullWidth={false}
          />
        </Grid>
      )}

      {loading ? (
        <Grid
          container
          spacing={2}
          direction={'column'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
            width: '100%'
          }}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : users?.length > 0 ? (
        <>
          <Grid item style={{ marginTop: 24 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>User type</StyledTableCell>
                    <StyledTableCell>Phone</StyledTableCell>

                    <StyledTableCell>Status</StyledTableCell>
                    {checkPrivilege(Privileges.level2, user) && (
                      <StyledTableCell>Action</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedUsers.map((row, index) => (
                    <UsersItem
                      row={row}
                      index={index}
                      setModal={setModal}
                      getAllAdmins={getAllAdmins}
                      user={user}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component='div'
              count={users.length}
              rowsPerPage={limit}
              page={page}
              onPageChange={(event, newpage) => {
                setPage(newpage)
              }}
              onRowsPerPageChange={event => {
                setLimit(parseInt(event.target.value, 10))
                setPage(0)
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          <NoRecordFound />
        </>
      )}
      <CustomModal data={modal} />
    </Grid>
  )
}

function UsersItem ({ row, index, setModal, getAllAdmins, user }) {
  var navigate = useNavigate()
  var dispatch = useDispatch()
  const [loadingBtn, setLoadingBtn] = useState(false)
  function changeUserStatus (status) {
    setLoadingBtn(true)
    var body = {
      adminEmail: row.adminEmail,
      status: status,
      usertype: row.adminType
    }
    serverData.changeAdminUserStatus(
      body,
      result => {
        if (result.status) {
          showToast(result.message, false)
          getAllAdmins()
        } else {
          showToast(result.message)
        }
        setLoadingBtn(false)
      },
      error => {
        setLoadingBtn(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  return (
    <TableRow key={index}>
      <StyledTableCell>
        {row.adminFirstName} {row.adminLastName}
      </StyledTableCell>
      <StyledTableCell>{row.adminEmail}</StyledTableCell>
      <StyledTableCell>{row.adminType}</StyledTableCell>
      <StyledTableCell>{row.adminPhone}</StyledTableCell>
      <StyledTableCell>{row.status}</StyledTableCell>
      {checkPrivilege(Privileges.level2, user) && (
        <StyledTableCell>
          <AppButton
            disabled={
              (row.adminType === 'superadmin' && user.usertype === 'admin') ||
              row.adminEmail === user.email
            }
            loading={loadingBtn}
            name={row.status === 'active' ? 'Inactive' : 'Active'}
            style={{ width: '100px', zIndex: 10 }}
            onClick={event => {
              event.stopPropagation()
              setModal({
                open: true,
                message: `Are you sure want to ${
                  row.status === 'active' ? 'Inactive' : 'Active'
                } this account?`,
                onClose: () => {
                  setModal({ open: false })
                },
                positiveBtnText: 'NO',
                negativeBtnText: 'YES',
                onClickNegativeButton: () => {
                  setModal({ open: false })
                  changeUserStatus(
                    row.status === 'active' ? 'inactive' : 'active'
                  )
                }
              })
            }}
          />
        </StyledTableCell>
      )}
    </TableRow>
  )
}
