import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { getUserDetails } from '../utils/LocalStorage'
import DrawerMenu from '../commonComponents/DrawerMenu'
import {
  drawerWidth,
  paddingHorizontal,
  paddingVertical
} from '../utils/AppConstants'
import WebSocketManager from '../api/WebSocketManager'

const ProtectedRoutes = ({ component: Component }) => {
  var user = getUserDetails()

  useEffect(() => {
    if (user) {
      WebSocketManager.init()

      return () => {
        console.log('Websocket Close in Protected route')
        WebSocketManager.socket.close()
      }
    }
  }, [])

  if (!user) {
    return <Navigate to={'/login'} />
  } else {
    return (
      <div
        style={{
          height: '100vh',
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            height: 64
          }}
        >
          <DrawerMenu user={user} />
        </div>

        <div
          style={{
            height: `calc(100% - 64px)`,
            overflow: 'auto',
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            padding: `${paddingVertical} ${paddingHorizontal}`
          }}
        >
          {typeof Component === 'object' ? (
            Component
          ) : (
            <Component user={user} />
          )}
        </div>
      </div>
    )
  }
}

export default ProtectedRoutes
