import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useState } from 'react'
import { isEmpty } from '../../utils/AppHelper'
import AppButton from '../../commonComponents/AppButton'
import TextEditor from '../../commonComponents/TextEditor'
import { useNavigate } from 'react-router-dom'
import serverData from '../../api/ServerData'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions/loginActions'
import { showToast } from '../../utils/AppHelper'
import { useTheme } from '@mui/styles'
import { TextBold } from '../../commonComponents/TextStyling'

export default function CreateNotification () {
  const [title, setTitle] = useState('')
  const [userType, setUserType] = useState('all')
  const [msg, setMsg] = useState('')
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  function validateForm () {
    const error = {}

    if (isEmpty(title)) {
      error.title = 'Title cannot be empty'
    }
    if (isEmpty(userType)) {
      error.userType = 'Please select type'
    }
    if (isEmpty(msg)) {
      error.msg = 'Message cannot be empty'
    }

    setError(error)
    return Object.keys(error).length === 0
  }

  function handleSubmit () {
    if (validateForm()) {
      setLoading(true)
      var body = {
        title: title,
        message: msg,
        usertype: userType === 'all' ? '' : userType
      }
      serverData.sendNotification(
        body,
        result => {
          if (result.status) {
            showToast(result.message, false)
            navigate('/')
          } else {
            showToast(result.message)
          }
          setLoading(false)
        },

        error => {
          setLoading(false)
          if (error.status === 401) {
            dispatch(logoutUser(navigate)) // eslint-disable-next-line
          }
        }
      )
    }
  }

  return (
    <Grid container spacing={0} style={{ flexDirection: 'column' }}>
      <Grid
        item
        style={{
          background: theme.palette.background.paper,
          padding: '24px',
          borderRadius: '6px'
        }}
      >
        <Grid container spacing={2} onSubmit='handleSubmit' component='form'>
          <Grid item xs={12}>
            <TextBold style={{ fontSize: '18px' }}>Add Notification</TextBold>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type='text'
              id='title'
              name='title'
              label='Title'
              value={title}
              required
              fullWidth
              onChange={e => {
                var { value } = e.target
                if (value.length < 255) {
                  setTitle(value)
                }
              }}
              error={error.title}
              helperText={error.title}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={error.userType}>
              <InputLabel id='userType'>User Type</InputLabel>
              <Select
                label='User Type'
                name='userType'
                value={userType}
                id='userType'
                onChange={event => setUserType(event.target.value)}
                error={error.userType}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'employer'}>Employer</MenuItem>
                <MenuItem value={'recruiter'}>Recruiter</MenuItem>
              </Select>
              <FormHelperText>{error.userType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin='normal'
              error={error.msg}
              style={{ margin: '0px' }}
            >
              <TextEditor
                getter={msg}
                setter={setMsg}
                placeholder={'Enter message here'}
              />

              <FormHelperText>{error.msg}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} marginTop={'16px'}>
          <AppButton
            name={'Save'}
            style={{
              marginRight: '16px',
              padding: '12px 24px',
              borderRadius: '8px'
            }}
            onClick={handleSubmit}
            fullWidth={false}
            loading={loading}
            disabled={false}
          />

          <AppButton
            name={'Cancel'}
            fullWidth={false}
            onClick={() => navigate('/faq')}
            style={{
              marginRight: '16px',
              padding: '12px 24px',
              borderRadius: '8px'
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
