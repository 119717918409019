import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'

import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import AppButton from '../../commonComponents/AppButton'
import { useNavigate } from 'react-router-dom'
import serverData from '../../api/ServerData'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import NoRecordFound from '../../commonComponents/NoRecordFound'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { checkPrivilege, showToast } from '../../utils/AppHelper'
import CustomModal from '../../commonComponents/CustomModal'
import { Privileges } from '../../utils/AppConstants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.shade,
    color: theme.palette.common.secondary,
    fontSize: 16,
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    padding: '8px 15px'
  }
}))

export default function FAQ ({ user }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [faqData, setFaqData] = useState([])
  var dispatch = useDispatch()

  const [modal, setModal] = useState({ open: false })

  const [loadingBtn, setLoadingBtn] = useState(false)

  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(function () {
    getFaqData()
  }, [])

  function getFaqData () {
    setLoading(true)
    serverData.getFaq(
      {},
      result => {
        if (result.status) {
          setFaqData(result.response)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate))
        }
      }
    )
  }

  return (
    <Grid container spacing={2}>
      {checkPrivilege(Privileges.level3, user) && (
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'right',
            width: '100%'
          }}
        >
          <AppButton
            name={'Add FAQ'}
            onClick={() =>
              navigate('/createFaq', { state: { data: {}, isEdit: false } })
            }
            fullWidth={false}
          />
        </Grid>
      )}
      <Grid
        item
        style={{
          width: '100%'
        }}
      >
        {loading ? (
          <Grid
            container
            spacing={2}
            direction={'column'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70vh',
              width: '100%'
            }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : faqData?.length > 0 ? (
          <>
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>User</StyledTableCell>
                      <StyledTableCell>Category</StyledTableCell>
                      <StyledTableCell>Question</StyledTableCell>
                      <StyledTableCell>Answer</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {faqData.map(row => (
                      <TableRow key={row.id}>
                        <StyledTableCell>
                          {row.usertype.charAt(0).toUpperCase() +
                            row.usertype.slice(1)}
                        </StyledTableCell>
                        <StyledTableCell>{row.category}</StyledTableCell>
                        <StyledTableCell>{row.question}</StyledTableCell>
                        <StyledTableCell>
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: row.answer
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <div style={{ display: 'flex' }}>
                            <Button
                              onClick={() => {
                                navigate('/createFaq', {
                                  state: { data: row, isEdit: true }
                                })
                              }}
                              startIcon={<EditIcon />}
                            />

                            <AppButton
                              disabled={false}
                              loading={loadingBtn}
                              variant='text'
                              onClick={() => {
                                setModal({
                                  open: true,
                                  title: 'Confirm to delete FAQ?',
                                  message: `Are you sure, you want to delete this FAQ question?`,
                                  onClose: () => {
                                    setModal({ open: false })
                                  },
                                  positiveBtnText: 'Delete',
                                  onClickPositiveButton: () => {
                                    setLoadingBtn(true)
                                    serverData.deleteFaq(
                                      { id: row.id },
                                      result => {
                                        if (result.status) {
                                          getFaqData()
                                          setModal({ open: false })
                                          showToast(result.message, false)
                                        } else {
                                          showToast(result.message)
                                        }
                                        setLoadingBtn(false)
                                      },
                                      error => {
                                        showToast(error)
                                        setLoadingBtn(false)
                                      }
                                    )
                                  },
                                  negativeBtnText: 'Cancel',
                                  onClickNegativeButton: () => {
                                    setModal({ open: false })
                                  }
                                })
                              }}
                              style={{
                                width: 'fit-content',
                                fontSize: '16px',
                                borderRadius: '8px'
                              }}
                              startIcon={<DeleteIcon />}
                            />
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component='div'
                count={faqData.length}
                rowsPerPage={limit}
                page={page}
                onPageChange={(event, newpage) => {
                  setPage(newpage)
                }}
                onRowsPerPageChange={event => {
                  setLimit(parseInt(event.target.value, 10))
                  setPage(0)
                }}
              />
            </Grid>
            {modal.open && <CustomModal data={modal} />}
          </>
        ) : (
          <NoRecordFound />
        )}
      </Grid>
    </Grid>
  )
}
