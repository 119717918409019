import { React, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import AppButton from './AppButton'
import { isEmpty } from '../utils/AppHelper'
import { useTheme } from '@mui/styles'

function SearchComponent ({ setOnSearch }) {
  const theme = useTheme()
  const [search, setSearch] = useState('')
  const [clearSearch, setClearSearch] = useState(false)

  const handleSearch = search => {
    if (clearSearch) {
      setOnSearch('')
      setSearch('')
      setClearSearch(false)
    } else if (!isEmpty(search)) {
      setOnSearch(search)
      setClearSearch(true)
    } else {
      setOnSearch('')
    }
  }

  return (
    <Grid
      container
      spacing={2}
      style={{ display: 'flex', justifyContent: 'right' }}
    >
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
        <TextField
          id='outlined-basic'
          variant='outlined'
          size='small'
          fullWidth
          value={search}
          placeholder={'Search'}
          onChange={e => {
            setSearch(e.target.value)
            setClearSearch(false)
          }}
          sx={{ input: { color: theme.palette.text.greyLight } }}
          style={{
            background: theme.palette.background.paper,
            borderRadius: '8px'
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <AppButton
          name={clearSearch ? 'Clear' : 'Search'}
          variant={clearSearch ? 'outlined' : 'contained'}
          onClick={() => {
            handleSearch(search)
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SearchComponent
