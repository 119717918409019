import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TextBold } from '../../commonComponents/TextStyling'
import AppButton from '../../commonComponents/AppButton'
import { isEmpty, showToast } from '../../utils/AppHelper'
import serverData from '../../api/ServerData'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../actions/loginActions'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 5,
  p: 2,
  width: '40%'
}

export default function AssigneeModal ({ data }) {
  var { open, jobId, managerId, getAllJobs, onClose } = data

  const [activeUsers, setActiveUsers] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [admin, setAdmin] = useState('')

  const [formError, setFormError] = useState({})

  const [loading, setLoading] = useState(false)

  useEffect(function () {
    getAdmins()
  }, [])

  function getAdmins () {
    serverData.getAllAdmins(
      {},
      result => {
        if (result.status) {
          const data = result.response.filter(
            activeItem =>
              activeItem.status === 'active' &&
              activeItem.adminType !== 'superadmin'
          )
          setActiveUsers(data)
        }
        setLoading(false)
      },
      error => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  const validateForm = () => {
    const error = {}

    if (isEmpty(admin)) {
      error.admin = 'Please choose an assignee.'
    }

    setFormError(error)
    return Object.keys(error).length === 0
  }

  const handleChangeAssignee = async () => {
    if (validateForm()) {
      setLoading(true)

      var body = {
        jobId: jobId,
        managerId: admin
      }
      await serverData.changeAssignee(
        body,
        data => {
          if (data.status) {
            showToast(data.message, false)
            setAdmin('')
            onClose()
            getAllJobs()
          } else {
            showToast(data.message)
          }
          setLoading(false)
        },
        error => {
          setLoading(false)
          showToast(error.statusText)
        }
      )
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextBold style={{ fontSize: '16px' }}>Change Assignee</TextBold>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth margin='normal' error={formError.admin}>
              <InputLabel id='admin'>Change Manager/Assignee</InputLabel>
              <Select
                id='admin'
                label='Change Manager/Assignee'
                value={admin}
                fullWidth
                onChange={event => setAdmin(event.target.value)}
                required
              >
                {activeUsers.map(item => (
                  <MenuItem
                    key={item.adminId}
                    value={item.adminId}
                    disabled={item.adminId === managerId}
                  >
                    {item.adminFirstName} {item.adminLastName} (
                    {item.adminEmail})
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formError.admin}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <AppButton
              name={'Assign'}
              onClick={handleChangeAssignee}
              loading={loading}
              style={{
                padding: '12px 26px',
                width: 'fit-content',
                borderRadius: '8px'
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
