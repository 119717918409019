import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  TextBold,
  TextRegular,
  TextSemiBold
} from '../../commonComponents/TextStyling'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import serverData from '../../api/ServerData'

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Grid,
  FormControl,
  FormHelperText
} from '@mui/material'
import Divider from '@mui/material/Divider'

import AppButton from '../../commonComponents/AppButton'
import {
  formatNumber,
  isEmpty,
  showToast,
  validateOnlyDigit
} from '../../utils/AppHelper'
import RejectModal from './RejectModal'
import { logoutUser } from '../../actions/loginActions'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import QuestionMarkOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { getUserDetails } from '../../utils/LocalStorage'
import CustomModal from '../../commonComponents/CustomModal'
// import moment from "moment";

import EditIcon from '@mui/icons-material/Edit'

export const useStyle = makeStyles({
  heading: {
    fontWeight: 700
  },
  answer: {
    fontWeight: 300
  }
})

export default function JobDetails () {
  var user = getUserDetails()
  const location = useLocation()
  const row = location.state || {}
  var navigate = useNavigate()
  var dispatch = useDispatch()
  var theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [rewardType, setRewardType] = useState('')
  const [enterAmount, setEnterAmount] = useState('')
  const [guarenteePeriod, setGuarenteePeriod] = useState('')
  const [commissionFromEmployer, setCommissionFromEmployer] = useState('')

  const [isEdit, setIsEdit] = useState(false)

  const [viewRejectModal, setViewRejectModal] = useState({
    open: false
  })

  const [modal, setModal] = useState({
    open: false
  })

  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (isEdit) {
      setCommissionFromEmployer(row.commissionFromEmployer)
      setRewardType(row.referralRewardType)
      setEnterAmount(row.referralRewardAmount)
      setGuarenteePeriod(row.guaranteePeriod)
    }
  }, [isEdit])

  const validateForm = () => {
    const error = {}
    if (isEmpty(commissionFromEmployer)) {
      error.commissionFromEmployer =
        'Please enter commission amount from employer.'
    }
    if (isEmpty(rewardType)) {
      error.rewardType = 'Please select reward type.'
    }
    if (rewardType === 'percentage' && (enterAmount < 1 || enterAmount > 100)) {
      error.enterAmount = 'Please enter valid percentage.'
    }
    if (isEmpty(enterAmount)) {
      error.enterAmount = 'Please enter amount.'
    }
    if (isEmpty(guarenteePeriod)) {
      error.guarenteePeriod = 'Please enter guarentee period.'
    }

    setFormError(error)
    return Object.keys(error).length === 0
  }

  const handleClick = isEdit => {
    if (validateForm()) {
      var body = {
        jobId: row.jobId,
        commissionFromEmployer: commissionFromEmployer,
        referralRewardType: rewardType,
        referralRewardAmount: enterAmount,
        guaranteePeriod: guarenteePeriod
      }
      setModal({
        open: true,
        message: isEdit
          ? `Are you sure want to update this job details?`
          : `Are you sure want to approve this job?`,
        onClose: () => {
          setModal({ open: false })
        },
        positiveBtnText: 'YES',
        onClickPositiveButton: () => {
          setLoading(true)
          ;(isEdit
            ? serverData.editJobCommissionDetails
            : serverData.updateJobStatus)(
            body,
            result => {
              if (result.status) {
                showToast(result.message, false)
                navigate(isEdit ? '/alljobs' : '/pendingjobs')
              } else {
                showToast(result.message)
              }
              setLoading(false)
            },
            error => {
              setLoading(false)
              if (error.status === 401) {
                dispatch(logoutUser(navigate)) // eslint-disable-next-line
              }
            }
          )
        },
        negativeBtnText: 'NO',
        onClickNegativeButton: () => {
          setModal({ open: false })
        }
      })
    }
  }
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px'
  }
  return (
    <Grid container containerspacing={2}>
      <Grid
        container
        style={{ justifyContent: 'space-between', marginBottom: '10px' }}
      >
        <Grid item>
          <TextBold style={{ fontSize: '20px' }}>Job Details</TextBold>
        </Grid>
        <Grid item>
          <AppButton
            name={'View Applications'}
            onClick={() =>
              navigate('/allapplications', {
                state: { jobId: row.jobId }
              })
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          // border: `1px solid ${theme.palette.border.main}`,
          padding: '16px',
          borderRadius: '6px',
          marginBottom: '20px',
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <BusinessOutlinedIcon fontSize='small' />
          <TextBold style={{ marginLeft: '15px' }}>Employer Details:</TextBold>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
          <TextRegular>
            Posted on: {dayjs(row?.createdAt).format('MM/DD/YYYY hh:mm a')}
          </TextRegular>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item style={containerStyle}>
            <TextRegular>Company Name:</TextRegular>
            <TextSemiBold>{row.companyname}</TextSemiBold>
          </Grid>
          <Grid item style={containerStyle}>
            <TextRegular>Email:</TextRegular>
            <TextSemiBold>{row?.employerEmail}</TextSemiBold>
          </Grid>
          <Grid item style={containerStyle}>
            <TextRegular>Phone:</TextRegular>
            <TextSemiBold>{row?.employerPhone}</TextSemiBold>
          </Grid>
          <Grid item style={containerStyle}>
            <TextRegular>Zip Code:</TextRegular>
            <TextSemiBold>{row?.zipCode}</TextSemiBold>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          // border: `1px solid ${theme.palette.border.main}`,
          padding: '16px',
          borderRadius: '6px',
          flexDirection: 'column',
          marginBottom: '20px',
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Grid container spacing={0} style={{ margin: '10px 0px' }}>
          <Grid style={{ display: 'flex' }}>
            <WorkOutlineIcon />
            <Grid
              container
              style={{ flexDirection: 'column', marginLeft: '15px' }}
            >
              <TextBold style={{ fontSize: '16px' }}> {row?.jobTitle}</TextBold>
              <Grid container style={{ alignItems: 'center' }}>
                <TextRegular> {row.jobIndustryName}</TextRegular>
                <Divider style={{ margin: '0 8px ' }} orientation='vertical' />
                <Grid item>
                  <TextRegular> {row?.jobTypeName}</TextRegular>
                </Grid>
                <Divider style={{ margin: '0 8px ' }} orientation='vertical' />
                <TextRegular>
                  {`${row?.city || ''}, ${row?.state || ''}, ${
                    row?.countryName || ''
                  }`}
                </TextRegular>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <TextBold>Job Title: </TextBold>
            <TextBold> {row?.jobTitle}</TextBold>
          </Grid> */}

          {/* <Grid item xs={6}>
            <TextRegular>Job Industry:</TextRegular>

            <TextRegular> {row.jobIndustryName}</TextRegular>
          </Grid> */}
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            {row?.jobType === '1' && (
              <>
                <Grid item style={containerStyle}>
                  <TextRegular>Contract Duration:</TextRegular>
                  <TextSemiBold>
                    {' '}
                    {row?.contractDuration} {row?.durationType}
                  </TextSemiBold>
                </Grid>
                <Grid item style={containerStyle}>
                  <TextRegular>Contract Start Date:</TextRegular>
                  <TextSemiBold>
                    {' '}
                    {dayjs(row?.desiredStartDate).format('MM/DD/YYYY')}
                  </TextSemiBold>
                </Grid>
                <Grid item style={containerStyle}>
                  <TextRegular>
                    Candidate submission limit per supplier:
                  </TextRegular>
                  <TextSemiBold> {row?.supplierLimit}</TextSemiBold>
                </Grid>
                <Grid item style={containerStyle}>
                  <TextRegular>Additional Compensation:</TextRegular>
                  <TextSemiBold> {row?.jobBenefits}</TextSemiBold>
                </Grid>
              </>
            )}

            <Grid item style={containerStyle}>
              <TextRegular>Job Function: </TextRegular>

              <TextSemiBold> {row.jobFunctionName}</TextSemiBold>
            </Grid>

            <Grid item style={containerStyle}>
              <TextRegular>Education Level: </TextRegular>
              <TextSemiBold> {row.educationLevelName}</TextSemiBold>
            </Grid>
            <Grid item style={containerStyle}>
              <TextRegular>Experience Level:</TextRegular>
              <TextSemiBold> {row.experienceLevelName}</TextSemiBold>
            </Grid>
            <Grid item style={containerStyle}>
              <TextRegular>Rejection Period:</TextRegular>
              <TextSemiBold>
                {row?.requiredExpYear} years{' '}
                {row?.requiredExpMonth !== 0 && (
                  <> {row?.requiredExpMonth} months</>
                )}
              </TextSemiBold>
            </Grid>
            {/* <Grid item style={containerStyle}>
              <TextRegular>Visa Sponsorship:</TextRegular>
              <TextSemiBold> {row?.visaSponsorship}</TextSemiBold>
            </Grid> */}
            <Grid item style={containerStyle}>
              <TextRegular>Relocation Assistance:</TextRegular>
              <TextSemiBold>
                <>
                  <div>{row?.relocationAssistanceName}</div>
                  <div>{row?.jobRelocationAssistanceText}</div>
                </>
              </TextSemiBold>
            </Grid>
            <Grid item style={containerStyle}>
              <TextRegular>Work Location:</TextRegular>
              <TextSemiBold>
                <>
                  <div>{row?.workLocationTypeName}</div>
                  <div>{row?.workLocationInfo}</div>
                </>
              </TextSemiBold>
            </Grid>

            {/* </Grid>

        <Grid container spacing={0} style={{ margin: "10px 0px" }}> */}
            {/* <Grid item xs={12}>
            <TextRegular>Job Type:</TextRegular>
            <TextRegular> {row?.jobTypeName}</TextRegular>
          </Grid> */}

            {(row?.jobType === '2' ||
              row?.jobType === '3' ||
              row?.jobType === '4') && (
              <>
                <Grid item style={containerStyle}>
                  <TextRegular>Compensation Amount:</TextRegular>
                  <TextSemiBold>
                    {' '}
                    {row?.jobCompensationAmountMin} -{' '}
                    {row?.jobCompensationAmountMax}
                  </TextSemiBold>
                </Grid>
                {row?.jobCompensationText !== '' && (
                  <Grid item style={containerStyle}>
                    <TextRegular>Compensation:</TextRegular>
                    {/* <TextRegular> {row?.jobCompensationText}</TextRegular> */}
                    <TextSemiBold> jobCompensationText</TextSemiBold>
                  </Grid>
                )}
              </>
            )}
            {row?.jobType === '5' && (
              <>
                <Grid item xs={4}>
                  <TextRegular>Rejection period:</TextRegular>
                  <TextRegular> {row?.rejectionPeriod}</TextRegular>
                </Grid>
                <Grid item xs={8}>
                  <TextRegular>Rejection reason:</TextRegular>
                  {row?.rejectionReason?.map((queAns, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      style={{ display: 'flex', lineHeight: '2rem' }}
                    >
                      <TextSemiBold>
                        {index + 1}. {queAns?.reason}
                      </TextSemiBold>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            <Grid item style={containerStyle}>
              <TextRegular>Total Position:</TextRegular>
              <TextSemiBold> {row?.totalPositions}</TextSemiBold>
            </Grid>
            <Grid item style={containerStyle}>
              <TextRegular>Close Position:</TextRegular>
              <TextSemiBold> {row?.closedPositions}</TextSemiBold>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '15px 0' }} />
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <TipsAndUpdatesOutlinedIcon fontSize='small' />
            <TextRegular style={{ marginLeft: '15px' }}>
              Skills: &nbsp;
            </TextRegular>
            <TextSemiBold>
              {' '}
              {row.skillRequired
                .split(',')
                .map(skill => skill.trim())
                .map(skill => skill.charAt(0).toUpperCase() + skill.slice(1))
                .join(', ')}
            </TextSemiBold>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px'
            }}
          >
            <DescriptionOutlinedIcon fontSize='small' />
            <TextRegular>Job Description:</TextRegular>
          </Grid>

          <Grid item xs={12}>
            <TextSemiBold>
              <Box
                dangerouslySetInnerHTML={{
                  __html: row.jobDescription
                }}
              />
            </TextSemiBold>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '15px 0' }} />

        <Grid container spacing={0} style={{ margin: '10px 0px' }}>
          <Grid item xs={12}>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <QuestionMarkOutlinedIcon fontSize='small' />
              <TextBold style={{ marginLeft: '15px' }}>
                Pre Screen Questions:
              </TextBold>
            </Grid>
            {row?.preScreenQuestions?.map((queAns, index) => (
              <Grid
                key={index}
                item
                xs={12}
                style={{ display: 'flex', lineHeight: '2rem' }}
              >
                <TextSemiBold>
                  {index + 1}. {queAns?.question}
                </TextSemiBold>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Divider sx={{ margin: '15px 0' }} />

        <Grid container spacing={0} style={{ margin: '10px 0px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <QuestionMarkOutlinedIcon fontSize='small' />
            <TextBold style={{ marginLeft: '15px' }}>
              Employers Details:
            </TextBold>
          </Grid>
          <Grid item style={containerStyle}>
            <TextRegular>Sub Employer:</TextRegular>
            <TextSemiBold> {row?.subEmployers}</TextSemiBold>
          </Grid>
          <Grid item style={containerStyle}>
            <TextRegular>Emails:</TextRegular>
            <TextSemiBold> {row?.emailCc}</TextSemiBold>
          </Grid>

          <Grid item style={containerStyle}>
            <TextRegular>Job order number:</TextRegular>
            <TextSemiBold> {row?.jobOrderNumber}</TextSemiBold>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          // border: `1px solid ${theme.palette.border.main}`,
          padding: '16px',
          borderRadius: '6px',
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Grid item xs={11}>
          <TextBold style={{ fontSize: '16px' }}>Details</TextBold>
        </Grid>

        {row.status !== 'pending' && (
          <>
            {isEdit === false && (
              <Grid item xs={1}>
                <AppButton
                  name={'Edit'}
                  variant='text'
                  style={{ color: theme.palette.primary.main }}
                  startIcon={<EditIcon style={{ width: 16, height: 16 }} />}
                  onClick={() => setIsEdit(true)}
                />
              </Grid>
            )}
            {[
              {
                label: 'Commission from Employer',
                value: row.commissionFromEmployer
              },
              {
                label: 'Referral Reward Type',
                value: row.referralRewardType
              },
              {
                label: 'Referral Reward Amount',
                value: formatNumber(row.referralRewardAmount)
              },
              {
                label: 'Guarantee Period',
                value: `${row.guaranteePeriod} Days`
              },
              {
                label: 'Assignee name',
                value: `${row.adminFirstName} ${row.adminLastName}`
              }
              // {
              //   label: 'Rejected on',
              //   value: moment(row?.rejectedOn).format('D MMMM YYYY, dddd')
              // }
            ].map(item => (
              <Grid item xs={4}>
                <TextBold>{item.label}: </TextBold>
                <TextRegular>{item.value}</TextRegular>
              </Grid>
            ))}
          </>
        )}

        {((row.status === 'pending' && user.usertype !== 'superadmin') ||
          isEdit === true) && (
          <>
            <Grid
              container
              spacing={2}
              direction='row'
              alignItems='center'
              style={{ marginTop: '4px' }}
            >
              <Grid item xs={4}>
                <TextField
                  sx={{ input: { color: 'black' } }}
                  required
                  fullWidth
                  label='Commission from Employer'
                  name='commission'
                  value={commissionFromEmployer}
                  onChange={e => {
                    var { value } = e.target
                    if (value.length < 12) {
                      if (validateOnlyDigit(value)) {
                        setCommissionFromEmployer(e.target.value)
                      }
                    }
                  }}
                  error={formError.commissionFromEmployer}
                  helperText={formError.commissionFromEmployer}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  margin='normal'
                  error={formError.rewardType}
                >
                  <InputLabel id='rewardType'>Referral Reward Type</InputLabel>
                  <Select
                    id='rewardType'
                    labelId='rewardType-label'
                    label='Referral Reward Type'
                    value={rewardType}
                    fullWidth
                    onChange={e => setRewardType(e.target.value)}
                    sx={{ color: theme.palette.text.secondary }}
                    required
                  >
                    <MenuItem value='fixed'>Fixed referral reward</MenuItem>
                    <MenuItem value='percentage'>Percentage</MenuItem>
                    <MenuItem value='hourly'>Per Hour</MenuItem>
                  </Select>
                  <FormHelperText>{formError.rewardType}</FormHelperText>
                </FormControl>
              </Grid>
              {rewardType !== '' && (
                <Grid item xs={4}>
                  <TextField
                    sx={{ input: { color: 'black' } }}
                    required
                    fullWidth
                    label='Reward Amount'
                    name='enterAmount'
                    value={enterAmount}
                    onChange={e => {
                      var { value } = e.target
                      if (value.length < 10) {
                        if (validateOnlyDigit(value)) {
                          setEnterAmount(e.target.value)
                        }
                      }
                    }}
                    error={formError.enterAmount}
                    helperText={formError.enterAmount}
                  />
                </Grid>
              )}

              <Grid item xs={4}>
                <TextField
                  label='Guarentee Period'
                  margin='normal'
                  required
                  fullWidth
                  name='guarenteePeriod'
                  placeholder='In days'
                  id='guarenteePeriod'
                  value={guarenteePeriod}
                  onChange={e => {
                    var { value } = e.target
                    if (value.length < 12) {
                      if (validateOnlyDigit(value)) {
                        setGuarenteePeriod(e.target.value)
                      }
                    }
                  }}
                  error={formError.guarenteePeriod}
                  helperText={formError.guarenteePeriod}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item spacing={2} style={{ marginTop: '10px' }}>
                <AppButton
                  type='submit'
                  onClick={() => handleClick(isEdit)}
                  name={isEdit ? 'Edit' : 'Approve'}
                  variant='contained'
                  style={{ marginRight: '15px' }}
                  disabled={false}
                  fullWidth={false}
                  loading={loading}
                />

                <AppButton
                  variant='outlined'
                  name={isEdit ? 'Cancel' : 'Reject'}
                  fullWidth={false}
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    isEdit
                      ? setIsEdit(false)
                      : setViewRejectModal({
                          open: true,
                          jobId: row.jobId,
                          onClose: () => {
                            setViewRejectModal({
                              open: false
                            })
                          }
                        })
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <CustomModal data={modal} />
      {viewRejectModal?.open && <RejectModal rejectJob={viewRejectModal} />}
    </Grid>
  )
}
