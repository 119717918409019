import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TextBold, TextHover, TextRegular, TextSemiBold } from './TextStyling'
import NotificationIcon from '@mui/icons-material/Notifications'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import serverData from '../api/ServerData'
import { logoutUser } from '../actions/loginActions'
import moment from 'moment'

export default function NotificationsIcon ({ usertype }) {
  var theme = useTheme()
  var navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [notificationData, setNotifications] = useState({
    data: [],
    unreadCount: 0
  })
  const [openNotify, setOpenNotify] = useState(false)

  const handleCloseNotify = () => {
    setOpenNotify(false)
  }

  useEffect(() => {
    getNotification()
  }, [location.pathname])

  function getNotification () {
    serverData.getNotificationsCount(
      {},
      result => {
        if (result.status) {
          var { response } = result
          setNotifications(response)
        }
      },
      error => {
        if (error.status === 401) {
          dispatch(logoutUser(navigate)) // eslint-disable-next-line
        }
      }
    )
  }

  return (
    <div style={{ marginRight: '8px' }}>
      <IconButton
        id='notify-menu'
        style={{
          padding: '10px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'capitalize'
        }}
        onClick={() => {
          setOpenNotify(true)
        }}
        aria-controls={openNotify ? 'notify-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={openNotify ? 'true' : undefined}
      >
        <Badge badgeContent={notificationData.unreadCount} color='primary'>
          <TextHover>
            <NotificationIcon
              style={{ width: '20px', color: theme.palette.background.paper }}
            />
          </TextHover>
        </Badge>
      </IconButton>
      <Menu
        id='notify-menu'
        open={openNotify}
        onClose={handleCloseNotify}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: 300,
              overflow: 'hidden',
              filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.1))',
              mt: 4,
              borderRadius: '0px',
              transform: 'translateY(16px) !important',
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,

                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={document.getElementById('notify-menu')}
      >
        <Box
          style={{
            width: '100%',
            height: '4px',
            background: theme.palette.primary.main,
            padding: '0px',
            marginTop: '-7px'
          }}
        />

        {notificationData.data.length > 0 ? (
          notificationData?.data.map((item, index) => (
            <>
              <MenuItem
                key={index}
                onClick={() => {
                  navigate('/allnotifications', {
                    state: item
                  })
                  handleCloseNotify()
                }}
                style={{
                  flexDirection: 'column',
                  alignItems: 'baseline'
                }}
              >
                <TextSemiBold
                  style={{
                    color:
                      item.isRead === 0
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                  }}
                >
                  {item.title}
                </TextSemiBold>

                <TextRegular
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    color:
                      item.isRead === 0
                        ? theme.palette.text.primary
                        : theme.palette.text.disabled
                  }}
                >
                  {item.message.replace(/<[^>]*>/g, ' ')}
                </TextRegular>

                <TextRegular
                  style={{
                    color:
                      item.isRead === 0
                        ? theme.palette.primary.main
                        : theme.palette.text.disabled
                  }}
                >
                  {moment(item?.createdAt).fromNow()}
                </TextRegular>
              </MenuItem>
              <Divider />
            </>
          ))
        ) : (
          <div style={{ padding: 32 }}>
            <TextRegular>No new notifications</TextRegular>
          </div>
        )}
        <MenuItem
          onClick={() => {
            navigate('/allnotifications')
            handleCloseNotify()
          }}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <TextRegular>
            <TextHover>View all notifications</TextHover>
          </TextRegular>
        </MenuItem>
      </Menu>
    </div>
  )
}
