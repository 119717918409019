import React from 'react'
import appassets from '../utils/appassets'
import { TextRegular } from './TextStyling'

const NoRecordFound = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <div>
        <img src={appassets.emptydata} alt='empty-jobs' />
      </div>
      <div>
        <TextRegular style={{ fontSize: '18px' }}>No Record Found</TextRegular>
      </div>
    </div>
  )
}

export default NoRecordFound
