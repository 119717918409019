import { Box, Modal, Typography } from '@mui/material'
import React from 'react'
import { isEmpty } from '../utils/AppHelper'
import AppButton from './AppButton'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 5,
  p: 2
}

export default function CustomModal ({ data }) {
  var {
    open,
    title,
    message,
    onClose,
    disableOverlay = false,
    positiveBtnText = 'OK',
    onClickPositiveButton,
    negativeBtnText,
    onClickNegativeButton,
    child
  } = data
  return (
    <Modal open={open} onClose={disableOverlay ? null : onClose}>
      <Box sx={style}>
        {!isEmpty(title) && (
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            align='center'
            fontWeight={'bold'}
          >
            {title}
          </Typography>
        )}
        <Typography
          id='modal-modal-description'
          align='center'
          sx={{ mt: 2 }}
          style={{ whiteSpace: 'pre-line' }}
        >
          {message}
        </Typography>
        {child}

        <div style={{ display: 'flex' }}>
          <AppButton
            variant='text'
            name={positiveBtnText}
            onClick={onClickPositiveButton || onClose}
            style={{ marginTop: 10 }}
          />

          {negativeBtnText && onClickNegativeButton && (
            <AppButton
              variant='text'
              name={negativeBtnText}
              onClick={onClickNegativeButton}
              style={{ marginTop: 10 }}
            />
          )}
        </div>
      </Box>
    </Modal>
  )
}
