/* eslint-disable */
import { toast } from 'react-toastify'
import appstrings from './appstrings.js'

export const showToast = (text, isError = true) => {
  isError ? toast.error(text) : toast.success(text)
}

export const validateEmail = value => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return reg.test(value)
}

export const validatePhone = value => {
  let reg = /^\d{10}$/
  return reg.test(value)
}

export const validateZipCode = value => {
  let reg = /^\d{4,11}$/
  return reg.test(value)
}

export const validateOnlyDigit = value => {
  let reg = /^\d*$/
  return reg.test(value)
}

export const validatePassword = value => {
  let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&\s]{8,}$/
  return reg.test(value)
}

export const validateName = value => {
  let reg = /^[a-z ,.'-]+$/i
  return reg.test(value)
}

export const isEmpty = text => {
  if (text === '' || text === null || text === undefined) {
    return true
  } else {
    return false
  }
}

export const isEmptyJson = text => {
  if (Object.keys(text).length === 0) {
    return true
  } else {
    return false
  }
}

export const formatNumber = text => {
  return text?.toLocaleString('en-IN', {
    maximumFractionDigits: 2
  })
}

export const colorWithOpacity = (color, opacity) => {
  switch (opacity) {
    case 0.1:
      return color + '1a'
    case 0.2:
      return color + '33'
    case 0.3:
      return color + '4d'
    case 0.4:
      return color + '66'
    case 0.5:
      return color + '80'
    case 0.6:
      return color + '99'
    case 0.7:
      return color + 'b3'
    case 0.8:
      return color + 'cc'
    case 0.9:
      return color + 'e6'

    default:
      return color
  }
}

export const checkPrivilege = (level, user) => {
  return level.includes(user.usertype)
}
